import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Button, Card } from "react-bootstrap";
import { api } from "services";
import { useNavigate } from "react-router-dom";

const Index = () => {
  const [managers, setManagers] = useState([]);
  var navigate = useNavigate();

  useEffect(() => {
    api.accountManagers().then((res) => {
      setManagers(res);
    });
  }, []);

  const back = () => {
    navigate(-1);
  }

  const managerView = (item) => {
    return (
      <Card className="border-app m-3" style={{ width: "20rem" }}>
        <Card.Body>
          <Card.Title className="">
            <b className="text-secondary">{item.get("name")}</b>
          </Card.Title>
          <Card.Subtitle className="mb-2 text-app opacity-75">
            {item.get("location")} - {item.get("state")}
          </Card.Subtitle>
          <Card.Text>{item.get("discription")}</Card.Text>
          <div>
            <div className="m-2 managercontacts">
              <a
                href={`https://wa.me/${item.get("whatsapp")}`}
                target="_blank"
                className="manageranker"
              >
                <i className="bi bi-telephone m-2"></i>
                {item.get("phone")}
              </a>
            </div>
            <div className="m-2 managercontacts">
              <a
                href={`https://wa.me/${item.get("whatsapp")}`}
                target="_blank"
                className="manageranker"
              >
                <i className="bi bi-whatsapp m-2"></i>
                {item.get("whatsapp")}
              </a>
            </div>
          </div>
          <div>
            <div className="m-2 managercontacts">
              <a
                href={`mailto:${item.get("email")}`}
                target="_blank"
                className="manageranker"
              >
                <i className="bi bi-envelope m-2"></i>{" "}
                {item.get("email")}
              </a>
            </div>
          </div>
        </Card.Body>
      </Card>
    )
  }

  return (
    <div className="container">
      <div class="row mt-5">
        <div class="col-1">
          <a role="button" className="btn btn-app" onClick={back} title="Back">
            <i className="bi bi-arrow-left"></i>
          </a>
        </div>
        <div class="col-11">
          <h2 className="">Account Managers</h2>
        </div>
      </div>
      
      <hr />
      <div style={{ display: "flex" }} className="mt-5">
        {managers && managers.map((item, i) => managerView(item))}
      </div>
    </div>
  );
};

export default Index;
