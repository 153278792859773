import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { 
  faUserPlus, faArrowCircleLeft, faArrowCircleRight, faPencil
} from "@fortawesome/free-solid-svg-icons";
import "./index.css";

const ExpandableButton = (props) => {
    const { title, clickEvent, icon, clickForm } = props;
    //faUserPlus new-account-btn
    return (
        <div className="row">
          <div className="col-12 justify-content-end btn-outer" title={title}>
            <button
              className="second-step btn btn-danger px-3 py-2 my-3 expand-btn"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target={`#${clickForm}`}
              onClick={clickEvent}
            >
              <FontAwesomeIcon
                icon={icon}
                className="fs-4"
              />
              <span className="fs-3 mx-2 btn-inner-text fw-bold">
                {title}
              </span>
            </button>
          </div>
        </div>
    )
}
//fonts-for-buttons-label add-text 
//title=="Add Account" ? faUserPlus : faPencil
export default ExpandableButton;