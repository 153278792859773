import Parse from './parse.service'

export function fetchUser() {
    return Parse.User.current()
}

export function logout() {
    return Parse.User.logOut()
}

export function updateUser(userData) {
    const user = fetchUser();
    userData.businessName? user.set("businessName", userData.businessName) : null;
    userData.phone? user.set("phone", userData.phone) : null;
    userData.email? user.set("email", userData.email) : null;
    userData.typeOfBusiness? user.set("typeOfBusiness", userData.typeOfBusiness) : null;
    return user.save();
}


export function setOnboardingInfo(status) {
    const user = fetchUser()
    user.set('onboardingInfo', status)
    return user.save()
}


export function setTypeOfBusiness(typeOfBusiness) {
    const user = fetchUser()
    user.set('typeOfBusiness', typeOfBusiness)
    return user.save()
}

export function setBusinessName(businessName) {
    const user = fetchUser()
    user.set('businessName', businessName)
    return user.save()
}

export function sendVerificationEmail(email) {
    const status = new Promise((resolve, reject) => {
        const user = fetchUser()
        const randomEmail = 'someone@anymail.com'
        user.set('email', randomEmail)
        user.save()
            .then((result) => {
                user.set('email', email)
                user.save()
                    .then((result) => {
                        resolve('Verification email sent successfully!')
                    })
                    .catch((err) => {
                        reject('Something went wrong!')
                    })
            })
            .catch((err) => {
                reject('Something went wrong!')
            })
    })
    return status
}
