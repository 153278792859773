import { useEffect, useState } from "react";
import { api  } from "services";

function Index() {
    const [clubs, setClubs] = useState([]);

    useEffect(() => {
        fetchClubs();
    }, []);

    const joinClub = () => {

    }

    const fetchClubs = () => {
        api.getClubs()
        .then((clubs) => {
            //console.log(clubs[0].get("amount"));
            setClubs(clubs);
        })
        .catch((err) => console.log(err.message));
    }

    const fetchRounds = (clubId) => {

    }

    const fetchMemberDetail = (userId) => {
        
    }

    

    //people(members)
    //<Club data={club} />
    return (
        <div align="center">
            <br />
            <h3>Borrow or Invest to fulfill your dreams.</h3><br />
            <ul>
            {clubs.map((club, i) => {
                console.log(club.id);
                return (
                    <li key={i}>
                        <div className="row">
                            <div className="col-2">
                                {Math.abs(club.get("amount")).toLocaleString('en-IN', {
                                maximumFractionDigits: 0,
                                style: 'currency',
                                currency: 'INR'
                                })}
                            </div>
                            <div className="col-8">
                                <b>{club.get("name")}</b><br />
                                {Math.abs(club.get("amount")/club.get("member")).toLocaleString('en-IN', {
                                maximumFractionDigits: 0,
                                style: 'currency',
                                currency: 'INR'
                                })} monthly contribution
                            </div>
                            <div className="col-2">
                                <a href= {"/club/" + club.id} className="btn btn-outline-danger ">Select Club</a>
                            </div>
                        </div>
                        <hr />
                    </li>
                )
            })}
            </ul>
        </ div>
    )   
}

export default Index;