  import Parse from "./parse.service";

  export const sendOTP = async (mobile) => {
    const params = { mobile: mobile };
    return await Parse.Cloud.run("sendOTP", params)
      .then((res) => res)
      .catch((err) => err);
  };

  export const resendOTP = async (mobile, type) => {
    const params = { mobile: mobile, OTPtype: type };
    return await Parse.Cloud.run("resendOTP", params)
      .then((res) => res)
      .catch((err) => err);
  };

  export const verifyOTP = async (mobile, otp) => {
    const params = { mobile: mobile, otp: otp };
    console.log(params);
    return await Parse.Cloud.run("verifyUser", params)
      .then((res) => res)
      .catch((err) => err);
  };
  
  export const sendPaidSMS = async (amount, party, url, mobile) => {
    const params = { amount: amount, party: party, url: url, mobile: mobile };
    return await Parse.Cloud.run("PaidSMS", params)
      .then((res) => res)
      .catch((err) => err);
  };
  
  export const sendReceiveSMS = async (amount, party, url, mobile) => {
    const params = { amount: amount, party: party, url: url, mobile: mobile };
    return await Parse.Cloud.run("ReceiveSMS", params)
      .then((res) => res)
      .catch((err) => err);
  };
  