import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
// import thunk from "redux-thunk";
import accountSlice from "./slices/accountSlice";
import transctionSlice from "./slices/transctionSlice";
import authSlice from "./slices/authSlice";
import cssSlice from "./slices/cssSlice";
import updateSlice from "./slices/updateSlice";
import rctransctionSlice from "./slices/rcTransctionSlice";
export const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    cssandloaders: cssSlice.reducer,
    accounts: accountSlice.reducer,
    transction: transctionSlice.reducer,
    rctransction: rctransctionSlice.reducer,
    updater: updateSlice.reducer,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
