import { create } from "zustand";
import { api } from "services";

const useUniversalStore = create((set, get) => ({
  user: null,
  accounts: [],
  transactions:[],
  accountId: null,
  editTranscation:null,
  deleteTransactions:{
    type:"",
    object:null
  },
  refresh:{
    accountRefresh:0,
    transactionRefresh:0,
    recursiveRefresh:0
  },
  fetchInitialData: async() => {
    try {
      const accounts = await api.getAccounts();
      const transactions = await api.getTransactions();
      set({accounts, transactions});
    } catch(error) {
      console.error("Failed to fetch initial data", error);
    }
  },
  updateTranscation:(obj)=>{
    set((state) => ({ ...state, editTranscation: obj }));
  },

  updateRefresh:(type)=>{
    switch(type) {
      case "account":
        set((state) => ({ ...state, refresh: { ...state.refresh, accountRefresh: state.refresh.accountRefresh + 1 } }));
        break;
      case "transaction":
        set((state) => ({ ...state, refresh: { ...state.refresh, transactionRefresh: state.refresh.transactionRefresh + 1 } }));
        break;
      case "recursive":
        set((state) => ({ ...state, refresh: { ...state.refresh, recursiveRefresh: state.refresh.recursiveRefresh + 1 } }));
        console.log("yeah yeah im logging");
        break;
      default:
        break;
    }
  },
  
  write(obj) {
    if (typeof obj === "function") {
      set((state) => obj(state));
    } else {
      set((state) => ({ ...state, ...obj }));
    }
  }
}));

export default useUniversalStore;
