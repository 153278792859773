import accounting from "accounting";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useParams } from "react-router-dom";
import { api } from "services";
import useUniversalStore from "store/universalStore";

const Account = ({account}) => {
    const navigate = useNavigate();
    const { id } = useParams();

    const goToKhata = () => {
        navigate("/khata/" + account.id);
    }
    
    // Reopen a closed account
    const reOpenAccount = () => {
        alert("reopen");
        api.reopenAccount(account.id).then((result) => {
            //updateAccountsState(result);
        });
    }

    //Confirm Account Closure
    /*const confirmClose = useCallback(() => {
        write((prev) => {
          let tempVar = { ...prev.deleteTransactions };
          tempVar.type = "AccountClose";
          tempVar.object = account;
          return { ...prev, deleteTransactions: tempVar };
        });
      }, []);*/

    // Handle account closure
    const closeAccount = () => {
      if (account.get("balance") === 0) {
        api.closeAccount(account.id).then((result) => {
          updateAccountsState(result);
        });
      } else {
        alert("Can't delete active account ");
      }
    }

    const nowTime = new Date().getTime();
    const remindBool = account.get("date")
        ? nowTime > account.get("date").getTime() && account.get("enabled")
        : null;

    const reminderView = () => {
        return (
            <i
            className="bi bi-patch-exclamation-fill text-danger me-2"
            title="Payment Due!"
            ></i>
        )
    }

    const DropdownMenu = () => (
        <div className="dropdown">
            <a className="rounded-circle btn bg-not-transparent-on-hover"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false">
                <i className="bi bi-three-dots-vertical"></i>
            </a>
            <ul className="dropdown-menu">
                <li className="py-1 dropdown-item">
                    {account.get("cancelled") ? 
                        <button className="w-100 btn btn-outline-danger"
                            onClick={(e) => reOpenAccount()}>
                            <i className="bi bi-arrow-clockwise"></i>
                            <span className="mx-3">Reopen Account</span>
                        </button> :
                        <button className="w-100 btn"
                            data-bs-toggle="offcanvas"
                            data-bs-target="#editAccDrawer">
                            <FontAwesomeIcon icon={faPencil} size="1x" />
                            <span className="mx-3">Edit</span>
                        </button>
                    }
                </li>
                {account.get("balance") == 0 && !account.get("cancelled") ? (
                    <li className="py-1 dropdown-item">
                        <button className="w-100 btn btn-danger"
                            data-bs-toggle="modal" 
                            data-bs-target="#delModal">
                                <i className="bi bi-x-circle-fill"></i>
                                <span className="mx-3">Close</span>
                        </button>
                    </li>
                ) : null}
            </ul>
        </div>
    )

    return (
        <li role="button" className={`list-group-item ${account.id == id ? 
            "bg-danger bg-opacity-25" : "bg-transparent border-none"}`}>
            <div className="row fs-5">
                <div className="col-11 py-2 row" role="button" onClick={() => {
                    if(!account.get("cancelled")) {
                        goToKhata();
                    }
                }}>
                    <div className={`col-9 ${account.get("cancelled") ? 
                    " text-muted text-decoration-line-through" 
                    : "fw-bold" }`}>
                        {remindBool ? reminderView() : null}
                        {account.get("name").length > 50
                        ? account.get("name").substring(0, 50) + " ..."
                        : account.get("name")}
                    </div>
                    <div className="col-3">
                        <span className={`fw-bold ${account.get("balance") < 0
                            ? "text-danger" : "text-success"} 
                            ${account.get("cancelled") ? "text-muted text-decoration-line-through" : ""}`}>
                            {accounting.formatMoney(
                                Math.abs(account.get("balance")),
                                "₹",
                                0
                            )}
                        </span>
                    </div>
                </div>
                <div className="col-1">
                    <DropdownMenu />
                </div>
            </div>
        </li>
    )
}

export default Account;