import React, { useState } from 'react';

//<i className="bi bi-people" style={{"fontSize": 2, "color": cornflowerblue}}></i>
            
function NoKhata(){
    return (
        <div className="nokhata">
            <div className='row h-100'>
                <div className='col-12 my-auto'>
                    <i className="bi bi-people" style={{ fontSize: "5rem", color: "black" }}/>
                    <h3><i className="bi bi-arrow-left" style={{ fontSize: "1.4rem", color: "black" }}/> Ledgers</h3>
                    <p>Select a Khata</p>
                </div>
            </div>
            
        </div>
    )
}

export default NoKhata;