import React from "react";

function Passbook(){
  return (
    <div className="container-fluid">
      <h1>Passbook</h1>
    </div>
  )
}

export default Passbook;
