import React, { useState, useEffect } from "react";
import DatePicker from 'react-date-picker';
import { api } from 'services';
import { useDispatch, useSelector } from "react-redux";
import { getRcTransctions } from "redux/actions/rctransctionAction";
import Select from "react-select";

function EditRTxn() {
  const optionsInt = [
    { value: 1, label: "1 day (Daily)" },
    { value: 7, label: "7 days (Weekly)" },
    { value: 30, label: "30 (Monthly on same date every month)" },
    { value: 32, label: "30 (Monthly on 1st day every month)" },
    { value: 365, label: "365 days (Annually on same date)" },
    { value: null, label: "Custom" },
  ];
  const nowDate = new Date();
  nowDate.setDate(nowDate.getDate() + 1);
  nowDate.setHours(0, 0, 0, 0);
  const [multipler, setMultipler] = useState(1);
  const [values, setValues] = useState({
    amount: null,
    startDate: null,
    accname: "",
    detail: "",
    interval: optionsInt[0],
    nextTriggerDate: null
  });
  const allTransction = useSelector((state) => state.transction.allTransctions);
  const copiedTransactions = allTransction ? [...allTransction] : null;
  const updateData = useSelector((state) => state.updater.allUpdates);
  const forEdit = updateData ? updateData.editRcTxn : null;
  // console.log(forEdit, updateData);
  const dispatch = useDispatch();
  const minDate = new Date();
  minDate.setHours(0, 0, 0, 0);

  useEffect(() => {
    // console.log(updateData);
    if (forEdit?.id) {
      if (forEdit.get("accountId")) {
        let accname = forEdit.get("accountId").get("name");
        document.getElementById("editrctxnacc").value = accname ? accname : "";
      }
      let optIndex = 0;
      optionsInt.map((option, index) => {
        if (option.value === forEdit.get("interval")) {
          optIndex = index;
        }
      });
      setValues({
        amount: forEdit.get("amount"),
        startDate: forEdit.get("nextTriggerDate"),
        detail: forEdit.get("detail"),
        interval: optionsInt[optIndex]
      });
    }
  }, [updateData]);

  useEffect(() => {
    if (values.nextTriggerDate) {
      nextTriggerDateHandler();
    } else {
      setValues({
        ...values,
        nextTriggerDate: forEdit?.get("nextTriggerDate")
      });
    }
  }, [values.interval, values.startDate])

  const amountChangeHandler = (e) => {
    e.preventDefault();
    if (e.target.value < 0) {
      setMultipler(-1);
      setValues({
        ...values,
        amount: -e.target.value
      });
    } else {
      setValues({
        ...values,
        amount: e.target.value
      });
    }
  }

  const nextTriggerDateHandler = () => {
    if (values.interval) {
      if (values.interval === forEdit.get("interval") && values.startDate === forEdit.get("date")) {
        setValues({
          ...values,
          nextTriggerDate: forEdit.get("nextTriggerDate")
        });
      } else {
        const tempDate = values.startDate;
        switch (values.interval.value) {
          case 1:
            tempDate.setDate(tempDate.getDate() + 1);
            break;
          case 7:
            tempDate.setDate(tempDate.getDate() + 7);
            break;
          case 30:
            tempDate.setMonth(tempDate.getMonth() + 1);
            break;
          case 32:
            tempDate.setDate(1);
            tempDate.setMonth(tempDate.getMonth() + 1);
            break;
          case 365:
            tempDate.setFullYear(tempDate.getFullYear() + 1);
            break;

          default:
            tempDate.setDate(tempDate.getDate() + 1);
            break;
        }
        setValues({
          ...values,
          nextTriggerDate: tempDate
        });

      }
    } else {
      setValues({
        ...values,
        nextTriggerDate: null
      });
    }
  };

  const dateChangeHandler = (value) => {
    setValues({
      ...values,
      startDate: value
    });
  }

  const handleChangeInterval = (val) => {
    setValues({
      ...values,
      interval: val
    });
  };

  const edit = (e) => {
    e.preventDefault();
    let id = updateData.editRcTxn.id;
    if (values.startDate !== forEdit.get("date")) {
      // console.log("date not same");
      const aa = forEdit.get("date");
      values.startDate.setHours(aa.getHours(), aa.getMinutes(), aa.getSeconds());
    }
    if (values.amount > 0 && values.amount <= 9999999) {
      api.EditRecursiveTxn(id, multipler * values.amount, values.detail, values.startDate, values.interval, values.nextTriggerDate).then((result) => {
        updateTransactionsState(result);
        // props.onTriggerTxns();
      });
      // Clear the form
      resetform();
    } else {
      alert("Enter an amount between 1 and 1,00,00,000₹.")
    }

  }

  const updateTransactionsState = (newTxn) => {
    const arrayIndex = copiedTransactions.findIndex(obj => obj.id == newTxn.id);
    copiedTransactions[arrayIndex] = newTxn;
    dispatch(getRcTransctions(copiedTransactions));
  }

  document.getElementById('editRcTxnDrawer')?.addEventListener('hide.bs.offcanvas', function (e) {
    resetform();
  });

  const resetform = () => {
    let txnform = document.getElementById("EditRcTxnform");
    txnform.reset();
    setValues({
      amount: null,
      startDate: null,
      accname: "",
      detail: "",
      interval: null,
      nextTriggerDate: forEdit?.get("nextTriggerDate")
    })
  }

  return (
    <div className="offcanvas offcanvas-end text-danger" tabIndex="-1" id="editRcTxnDrawer" aria-labelledby="">
      <div className="offcanvas-header bg-danger text-light">
        <h3 className="offcanvas-title">Edit Transaction</h3>
        <button type="button" className="btn-close text-reset bg-light" data-bs-dismiss="offcanvas" aria-label="Close" onClick={() => resetform()}></button>
      </div>
      <div className="offcanvas-body">
        <div className="container-fluid bg-white mb-5">
          <div className="row">
            <div className="col-12">
              <form onSubmit={edit} id="EditRcTxnform">
                <div className="row py-3">
                  <div className="col-md-6">
                    <label className="fw-bolder fs-smaller fonts-for-buttons-label" htmlFor="rctxndate">Amount</label>
                  </div>
                  <div className="col-md-6">
                    <label className="fw-bolder fs-smaller fonts-for-buttons-label" htmlFor="rctxndate">Start Date</label>
                  </div>
                  <div className="col-md-6">
                    <input type="number" className="form-control" id="edittxnamount" placeholder="Amount" value={values.amount} onChange={amountChangeHandler} min="1" max="9999999" disabled={true} required title="You cannot edit amount" />
                  </div>
                  <div className="col-md-6 border-present mt-4 mt-md-0">
                    <DatePicker className="form-control w-100" id="editrctxndate" value={values.startDate} onChange={dateChangeHandler} minDate={minDate} />
                  </div>
                </div>
                <div className="py-3">
                  <input type="text" className="form-control" id="editrctxnacc" value={values.accname} placeholder="Account Name" list="accountOptions" title="You cannnot edit account" disabled />
                </div>
                <div className="py-3">
                  <input type="text" className="form-control" id="edittxndetail" placeholder="Details" maxLength="50" value={values.detail} onChange={(e) => setValues({ ...values, detail: e.target.value })} />
                </div>
                <div className="py-3">
                  <Select
                    options={optionsInt}
                    value={values.interval}
                    classNamePrefix="rs"
                    placeholder="Time Interval"
                    onChange={handleChangeInterval}
                    required
                    styles={{
                      option: (baseStyles, state) => ({
                        ...baseStyles,
                        color: state.isSelected ? 'white' : 'black',
                        fontSize: 14,
                        backgroundColor: (state.isFocused || state.isSelected) ? (state.isFocused ? '#FFCCCB' : '#dc3545') : 'white'
                      }),
                      menuList: (baseStyles, state) => ({
                        ...baseStyles,
                        backgroundColor: 'white'
                      }),
                      clearIndicator: () => ({
                        color: 'red'
                      })
                    }}
                  />
                </div>
                <div className="py-2">{values.nextTriggerDate ? "Will start on: " + values.nextTriggerDate.toDateString() : "N/A"}</div>
                <div className="row my-3">
                  <div className="col-md-12 d-grid">
                    <button type="submit" className="btn btn-outline-danger block py-3" data-bs-dismiss="offcanvas">
                      <b className="fs-5">Update</b>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default EditRTxn;
