import Parse from "./parse.service";
import base64toBlob from "base64toblob";

const Account = Parse.Object.extend("Account");
const Transaction = Parse.Object.extend("Transaction");
const Account_managers = Parse.Object.extend("Account_managers");
const Recurring = Parse.Object.extend("Recurring");
const Reminder = Parse.Object.extend("Reminder");
const User = Parse.Object.extend("_User");
const Club = Parse.Object.extend("Club");
const Contacts = Parse.Object.extend("Contacts");

import { auth } from "./index";
import moment from "moment";

const date = new Date();

export function fetchUser() {
  return Parse.User.current();
}

export const createAccount = async (
  name,
  phone,
  address,
  markup,
  profilePhoto
) => {
  const Account = Parse.Object.extend("Account");
  const newAccount = new Account();
  newAccount.set("name", name);

  if (phone) {
    newAccount.set("phone", phone);
  }
  if (address) {
    newAccount.set("address", address);
  }
  if (markup) {
    newAccount.set("markup", markup);
  }

  if (profilePhoto) {
    const parseFile = new Parse.File(profilePhoto.name, profilePhoto);
    newAccount.set("profilePhoto", parseFile);
  }
  try {
    newAccount.set("userId", auth.fetchUser());
    const savedAccount = await newAccount.save();
    return savedAccount;
  } catch (error) {
    console.error("Error creating account:", error);
    throw new Error("Failed to create account");
  }
};

// Transactions

export function transaction(_account, amount, detail, attachment, date) {
  console.log("Accounts", _account);

  const transaction = new Transaction();

  if (attachment) {
    transaction.set(
      "attachment",
      "https://bahikhatabills.s3.amazonaws.com/cb97925b28803a604429df0b4fb43f6f_download1.png"
    );
    const name = attachment.name;
    var parseFile = new Parse.File(name, attachment);
    parseFile.save().then(
      (result) => {
        transaction.set("attachment", result._url);
        transaction.save();
      },
      (error) => {
        console.error("error" + error);
      }
    );
  }

  if (!!_account) {
    console.log(_account);
    return getAccountByName(_account).then((myAccount) => {
      if (myAccount) {
        transaction.set("accountId", myAccount);
        transaction.set("amount", amount);
        transaction.set("detail", detail);
        transaction.set("cancelled", false);
        transaction.set("date", date);
        transaction.set("userId", auth.fetchUser());
        updateBalance(myAccount.id, amount);
        return transaction.save();
      } else {
        return account(_account, "").then((acc) => {
          if (acc) {
            transaction.set("accountId", acc);
            transaction.set("amount", amount);
            transaction.set("detail", detail);
            transaction.set("date", date);
            transaction.set("cancelled", false);
            transaction.set("userId", auth.fetchUser());
            acc.set("balance", acc.get("balance") + amount);
            acc.save();
            return transaction.save();
          }
        });
      }
    });
  } else {
    transaction.set("amount", amount);
    transaction.set("detail", detail);
    transaction.set("date", date);
    transaction.set("cancelled", false);
    transaction.set("userId", auth.fetchUser());
    return transaction.save();
  }
  return transaction.save();
}

export function uploadFile(file) {
  const trackingUpload = new Promise((resolve, reject) => {
    if (file) {
      const name = file.name;
      var parseFile = new Parse.File(name, file);
      parseFile
        .save()
        .then(function (result) {
          resolve(result);
        })
        .catch((error) => {
          reject(error);
        });
    } else {
      resolve(null);
    }
  });
  return trackingUpload;
}

export const getTransactions = (first, next) => {
  const query = new Parse.Query(Transaction);
  // query.include("accountId");
  query.limit(1000000);

  query.equalTo("userId", auth.fetchUser());
  query.greaterThan("date", first);
  query.lessThan("date", next);
  return query
    .find()
    .then((results) => {
      if (results) return results;
    })
    .catch((error) => error.message);
}

export const getAllTransactions = () => {
  const query = new Parse.Query(Transaction);
  query.limit(1000000);
  query.ascending("date");
  query.equalTo("userId", auth.fetchUser());
  return query
    .find()
    .then((results) => {
      return results;
    })
    .catch((error) => error.message);
};

export function getTxnsByAccountId(id) {
  return getAccountById(id).then((account) => {
    const query = new Parse.Query(Transaction);
    query.limit(1000000);
    query.ascending("date");
    query.equalTo("userId", auth.fetchUser());
    query.equalTo("accountId", account);
    return query
      .find()
      .then((results) => {
        if (results) return results;
      })
      .catch((error) => error.message);
  });
}

export function getTxnsByAccountIdByDate(id, first, next) {
  return getAccountById(id).then((account) => {
    const query = new Parse.Query(Transaction);
    query.equalTo("accountId", account);
    query.greaterThan("date", first);
    query.limit(1000000);

    query.lessThan("date", next);
    query.ascending("date");
    return query
      .find()
      .then((results) => {
        if (results) return results;
      })
      .catch((error) => error.message);
  });
}

export function getTxnById(id) {
  const query = new Parse.Query(Transaction);
  query.equalTo("userId", auth.fetchUser());
  query.equalTo("objectId", id);
  query.limit(1);
  return query
    .find()
    .then((results) => {
      if (results) return results;
    })
    .catch((error) => error.message);
}

export function getTransactionById(id) {
  const query = new Parse.Query(Transaction);
  query.limit(1);
  query.equalTo("objectId", id);
  return query
    .find()
    .then((results) => {
      return results[0];
    })
    .catch((error) => error.message);
}

export function updateTransaction(id, newamount, _detail, date) {
  return getTransactionById(id)
    .then((transaction) => {
      const oldamount = transaction.get("amount");
      const account_object = transaction.get("accountId");
      const diffamount = newamount - oldamount;
      transaction.set("amount", newamount);
      if (account_object) {
        const accountId = account_object.id;
        updateBalance(accountId, diffamount);
      }
      transaction.set("detail", _detail);
      transaction.set("date", date);
      return transaction.save();
    })
    .catch((error) => error.message);
}

export function deleteTransaction(id) {
  return getTransactionById(id)
    .then((txn) => {
      txn.set("cancelled", true);
      // var accountId = txn.get('accountId');
      return txn.save();
    })
    .catch((error) => error.message);
}

// Accounts

export function account(name, phone) {
  // if (!getAccountByName(name)|| !getAccountByPhone(phone)) {
  const account = new Account();
  account.set("name", name);
  account.set("balance", 0);
  account.set("phone", phone);
  account.set("cancelled", false);
  account.set("userId", auth.fetchUser());
  return account.save();
  // }
  //  else console.warn("Error while creating account.");
}

export function getAccounts() {
  const query = new Parse.Query(Account);
  // query.include("userId");
  query.equalTo("userId", auth.fetchUser());
  query.ascending("name");
  query.ascending("cancelled");

  query.limit(1000000);

  // if (hideClosed) query.equalTo("cancelled", false);
  return query
    .find()
    .then((results) => {
      if (results) return results;
    })
    .catch((error) => error.message);
}

export const getAllAccounts = () => {
  const query = new Parse.Query(Account);
  query.limit(1000000);
  query.equalTo("userId", auth.fetchUser());
  return query
    .find()
    .then((results) => {
      return results;
    })
    .catch((error) => error.message);
};

export function getActiveAccounts() {
  const query = new Parse.Query(Account);
  query.equalTo("cancelled", false);
  query.ascending("name");
  query.limit(1000000);

  query.equalTo("userId", auth.fetchUser());
  return query
    .find()
    .then((results) => {
      if (results) return results;
    })
    .catch((error) => error.message);
}

export function getAccountByName(name) {
  const query = new Parse.Query(Account);
  query.limit(1);
  query.equalTo("name", name);
  query.equalTo("userId", auth.fetchUser());
  return query
    .find()
    .then((results) => {
      if (results.length) return results[0];
      else return results.length;
    })
    .catch((error) => {
      return error;
    });
}

export function getAccountById(id) {
  const query = new Parse.Query(Account);
  query.limit(1);
  query.equalTo("objectId", id);
  return query
    .find()
    .then((results) => {
      // console.log(results[0]);
      if (results.length != 0) return results[0];
      return null;
    })
    .catch((error) => {
      // error.message
      console.log(error);
    });
}

export function updateAccount(id, name, phone, address, markup, profilePhoto) {
  return getAccountById(id)
    .then((results) => {
      var account = results;
      if (account.get("name") !== name) {
        account.set("name", name);
      }
      if (account.get("phone") !== phone) {
        account.set("phone", phone);
      }
      if (account.get("address") !== address) {
        account.set("address", address);
      }
      if (account.get("markup") !== markup) {
        account.set("markup", markup);
      }

      const oldProfilePhoto = account.get("profilePhoto");
      if (profilePhoto !== oldProfilePhoto) {
        if (profilePhoto === null) {
          account.set("profilePhoto", null);
        } else if (profilePhoto instanceof File) {
          const parseFile = new Parse.File(profilePhoto.name, profilePhoto);
          account.set("profilePhoto", parseFile);
        } else if (
          oldProfilePhoto &&
          oldProfilePhoto._url !== profilePhoto._url
        ) {
          const parseFile = new Parse.File(profilePhoto.name, {
            uri: profilePhoto._url,
          });
          account.set("profilePhoto", parseFile);
        }
      }

      return account.save();
    })
    .catch((error) => error.message);
}

export function closeAccount(id) {
  return getAccountById(id)
    .then((account) => {
      account.set("cancelled", true);
      return account.save();
    })
    .catch((error) => error.message);
}

export function deleteAccount(acc) {
  return acc.destroy();
}

export function reopenAccount(id) {
  return getAccountById(id)
    .then((account) => {
      account.set("cancelled", false);
      return account.save();
    })
    .catch((error) => error.message);
}

export function sumTillDate(date) {
  const query = new Parse.Query(Transaction);
  query.limit(100000);
  query.equalTo("userId", auth.fetchUser());
  query.lessThan("date", date);
  query.equalTo("cancelled", false);

  return query
    .find()
    .then((results) => {
      if (results) {
        var sum = 0;
        results.map((result) => (sum += result.get("amount")));
        return sum;
      }
    })
    .catch((error) => error.message);
}

export function sumTillDateByAccount(date, id) {
  return getAccountById(id).then((account) => {
    const query = new Parse.Query(Transaction);
    query.limit(1000000);

    query.equalTo("accountId", account);
    query.lessThan("date", date);
    query.equalTo("cancelled", false);
    return query
      .find()
      .then((results) => {
        if (results) {
          var sum = 0;
          results.map((result) => (sum += result.get("amount")));
          return sum;
        }
      })
      .catch((error) => error.message);
  });
}

export function updateBalance(accountid, amount) {
  return getAccountById(accountid).then((myAccount) => {
    if (myAccount) {
      myAccount.set("balance", myAccount.get("balance") + amount);
      return myAccount.save();
    }
    return myAccount.save();
  });
}

// User Related Queries

export function getUserKhatabookFilter() {
  const user = auth.fetchUser();
  const filterBool = user.get("hideClosed");
  return filterBool;
}

export function setUserKhatabookFilter(data) {
  console.log(data);
  const user = auth.fetchUser();
  user.set("hideClosed", data);
  return user.save();
}

export function findFreshUser(id) {
  const query = new Parse.Query(User);
  query.limit(1);
  query.equalTo("objectId", id);
  return query
    .find()
    .then((results) => {
      return results[0];
    })
    .catch((error) => error.message);
}

// Account Managers

export const accountManagers = async () => {
  const query = new Parse.Query(Account_managers);
  const results = await query.find();
  return results;
};

// Monthly Reports

export const downloadMonthlyReport = async (id, startMonth, endMonth) => {
  const params = {
    id: id,
    startMonth: startMonth,
    endMonth: endMonth,
  };
  return await Parse.Cloud.run("getMonthlyreport", params)
    .then((res) => {
      if (startMonth !== "lifetime") {
        startMonth.setDate(startMonth.getDate() + 1);
      }
      const blob = base64toBlob(res, { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `Report(${
        startMonth === "lifetime"
          ? ""
          : moment(startMonth).format("MMM DD YYYY") + " - "
      }${moment(endMonth).format("MMM DD YYYY")}).pdf`;
      // a.download = "report.pdf";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
      return "success";
    })
    .catch((err) => {
      return err;
    });
};

// Recursive Transactions

export const AddRecursiveTxn = async (
  accountId,
  amount,
  detail,
  date,
  interval,
  nextTriggerDate
) => {
  const recurring = new Recurring();
  return getAccountById(accountId).then(async (myAccount) => {
    recurring.set("accountId", myAccount);
    recurring.set("userId", auth.fetchUser());
    recurring.set("amount", parseInt(amount, 10));
    recurring.set("date", date);
    recurring.set("enabled", true);
    recurring.set("detail", detail);
    recurring.set("interval", interval);
    recurring.set("nextTriggerDate", nextTriggerDate);
    return recurring.save();
  });
};

export function findRecursiveTxn(id) {
  const query = new Parse.Query(Recurring);
  query.limit(1);
  query.equalTo("objectId", id);
  return query
    .find()
    .then((results) => {
      return results[0];
    })
    .catch((error) => error.message);
}

export const EditRecursiveTxn = async (
  id,
  amount,
  detail,
  date,
  interval,
  nextTriggerDate
) => {
  return findRecursiveTxn(id).then(async (txn) => {
    txn.set("amount", amount);
    txn.set("detail", detail);
    txn.set("date", date);
    txn.set("interval", interval);
    txn.set("nextTriggerDate", nextTriggerDate);
    return await txn.save();
  });
};

export const DeleteRecursiveTxn = async (txn) => {
  // return findRecursiveTxn(id).then(async (txn) => {
  return txn.destroy();
  // }).catch((error)=> {
  //   return error;
  // })
};

export const EnableRecursiveTxn = async (id) => {
  return findRecursiveTxn(id)
    .then(async (txn) => {
      txn.set("enabled", true);
      return await txn.save();
    })
    .catch((error) => {
      return error;
    });
};

export const DisableRecursiveTxn = async (id) => {
  return findRecursiveTxn(id)
    .then(async (txn) => {
      txn.set("enabled", false);
      return await txn.save();
    })
    .catch((error) => {
      return error;
    });
};

export const fetchRecursiveTxns = async (id) => {
  return getAccountById(id).then(async (myAccount) => {
    const query = new Parse.Query(Recurring);
    query.limit(1000000);

    query.equalTo("accountId", myAccount);
    return await query
      .find()
      .then((res) => res)
      .catch((err) => err);
  });
};

export const getAllRcTransactions = () => {
  const query = new Parse.Query(Recurring);
  query.limit(1000000);
  query.ascending("date");
  query.equalTo("userId", auth.fetchUser());
  return query
    .find()
    .then((results) => {
      return results;
    })
    .catch((error) => error.message);
};

// Reminder

export const AddReminder = async (id, date, state) => {
  return getAccountById(id).then(async (myAccount) => {
    myAccount.set("reminder", {enabled:state, date:date});
    return await myAccount.save();
  });
};

export const toggleReminder = async (id) => {
  return getAccountById(id).then(async (myAccount) => {
    //myAccount.set("reminder", {enabled: !myAccount.get("enabled"));
    return await myAccount.save();
  });
};

export const getReminderAlert = async (id) => {
  const currentdate = date.toLocaleString("en-US", {
    hours12: true,
    day: "2-digit",
  });
  return getAccountById(id).then(async (result) => {
    const serverDate = result.get("date");
    if (serverDate == currentdate) {
      const res = await result;
      return { status: "success", response: res };
    } else {
      return { status: "error" };
    }
  });
};

// Clubs

export const getClubs = () => {
  const query = new Parse.Query(Club);
  //query.equalTo("userId", auth.fetchUser());
  return query
    .find()
    .then((results) => results)
    .catch((error) => error.message);
};

export const getClubById = (id) => {
  const query = new Parse.Query(Club);
  query.equalTo("objectId", id);
  query.limit(1);
  return query
    .first()
    .then((result) => result)
    .catch((error) => error.message);
};

export const getContacts = () => {
  const query = new Parse.Query(Contacts);
  query.equalTo("userId", auth.fetchUser());
  return query
    .find()
    .then((results) => results)
    .catch((error) => error.message);
};
