import React, { useEffect, useState } from "react";
import RecursiveTxns from "Forms/Recursive/RecursiveTxns";
import OneTimeTxn from "./OneTimeTxn";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getTransctions } from "redux/actions/transctionAction";
import { getReduxAccounts } from "redux/actions/accountActions";
import useMyStore from "store/useMyStore";
import { hideOffCanvas } from "utils";
import { Loading, Status } from "./shared";
import useUniversalStore from "store/universalStore";

function NewTxn() {
  const [txnType, setTxnType] = useState("onetime");
  const [openKhata, setOpenKhata] = useState({});
  const [isKhata, setIsKhata] = useState(false);
  const allAccounts = useUniversalStore((state) => state.accounts);
  const nowLocation = useLocation();

  useEffect(() => {}, []);

  useEffect(() => {
    getOpenKhata();
  }, [nowLocation.pathname]);

  const getOpenKhata = () => {
    if (allAccounts.length != 0) {
      const tempurl = nowLocation.pathname;
      setIsKhata(() => tempurl.includes("khata/"));
      const khataId = nowLocation.pathname.split("khata/")[1];
      if (khataId && khataId !== openKhata?.id) {
        const foundKhata = allAccounts.find(
          (account) => account.id === khataId
        );
        setOpenKhata(foundKhata);
      }
    }
  };

  document
    .getElementById("addTxnDrawer")
    ?.addEventListener("hide.bs.offcanvas", function (e) {
      setTxnType(() => "onetime");
    });

  return (
    <div
      className="offcanvas offcanvas-end text-danger"
      tabIndex="-1"
      id="addTxnDrawer"
      aria-labelledby="offcanvasExampleLabel"
    >
      <div className="offcanvas-header bg-app text-light">
        <h3 className="offcanvas-title">Add Transaction</h3>
        <dialog
          style={{
            backgroundColor: "black",
          }}
          id="Loadingdialog"
          className="d-flex justify-content-center align-content-center text-danger border-0 "
        >
          <div
            className="spinner-border spinner-border-sm my-auto me-2"
            role="status"
          ></div>{" "}
          <span className="fs-5">Loading</span>
        </dialog>
        <dialog
          id="successdialog"
          className="fs-1 animate__animated animate__bounceIn"
        >
          <i className="bi bi-patch-check-fill text-success"></i>
        </dialog>
        <button
          type="button"
          className="btn-close text-reset bg-light"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
      </div>
      <div className="offcanvas-body">
        <div className="container-fluid bg-white mb-5">
          {nowLocation.pathname.includes("khata/") ? (
            <>
              <div
                className="d-flex fs-5"
                onClick={(e) =>
                  setTxnType((prev) =>
                    prev === "onetime" ? "recurring" : "onetime"
                  )
                }
                type="button"
              >
                One Time
                <span className="mx-2">
                  {txnType === "onetime" ? (
                    <i className="bi bi-toggle2-on rotate-180"></i>
                  ) : (
                    <i className="bi bi-toggle2-on rotate-0"></i>
                  )}
                </span>
                Recurring
              </div>
              {/* <div className="d-none">
                <div className="btn btn-ouline-danger border-danger" role="button" onClick={(e) => setTxnType((a) => "onetime")}>
                  <input type="radio" id="oneTimeForm" role="button" checked={txnType === "onetime"} />
                  <label htmlFor="oneTimeForm" className="ms-2" role="button">One Time</label>
                </div>
                <div className="btn btn-ouline-danger border-danger ms-3" role="button" onClick={(e) => setTxnType((a) => "recurring")}>
                  <input type="radio" id="recurringForm" role="button" checked={txnType === "recurring"} />
                  <label htmlFor="recurringForm" className="ms-2" role="button">One Time</label>
                </div>
              </div> */}
              <div>
                {txnType === "onetime" ? (
                  <OneTimeTxn
                    openKhata={openKhata}
                    allAccounts={allAccounts}
                    getOpenKhata={getOpenKhata}
                    khataview={isKhata}
                  />
                ) : (
                  <RecursiveTxns
                    openKhata={openKhata}
                    allAccounts={allAccounts}
                    getOpenKhata={getOpenKhata}
                    khataview={isKhata}
                  />
                )}
              </div>
            </>
          ) : (
            <OneTimeTxn
              openKhata={openKhata}
              allAccounts={allAccounts}
              getOpenKhata={getOpenKhata}
              khataview={isKhata}
            />
          )}
        </div>
      </div>
    </div>
  );
}
export default NewTxn;
