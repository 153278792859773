import { createSlice } from "@reduxjs/toolkit";

const initialValues = {
  allTransctions: null,
};

const transctionSlice = createSlice({
  name: "transction",
  initialState: initialValues,
  reducers: {
    getTransctions: (state, action) => {
      state.allTransctions = action.payload;
    },
  },
});

export default transctionSlice;
