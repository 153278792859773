import { useState, useEffect } from "react";
import { ListGroup, Col, Dropdown } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import { api, auth } from "services";
import Txn from "./Txn";
import OpenBalance from "components/OpenBalance";
import CloseBalance from "components/CloseBalance";
import MonthView from "../MonthView";
import { metrics } from "themes";
const accounting = require("accounting");

function Khata(props) {
  let { id } = useParams();
  var [txns, setTxns] = useState([]);
  var [account, setAccount] = useState(null);
  var [timervar, setTimerVar] = useState(0);
  let [filterDate, setFilterDate] = useState(new Date());
  let [openBalance, setOpenBalance] = useState(0);
  let [closeBalance, setCloseBalance] = useState(0);
  let [loading, setLoading] = useState(true);
  let [reportLoading, setReportLoading] = useState(false);
  const loadingDialog = document.getElementById("Loadingdialog");
  let curruser = auth.fetchUser();
  let currDate = new Date();
  let maxCurrMonth = new Date(
    currDate.getFullYear(),
    currDate.getMonth() + 1,
    1
  );
  let pseudoDate1 = new Date(
    filterDate.getFullYear(),
    filterDate.getMonth(),
    0,
    23,
    59,
    59,
    999
  );
  let pseudoDate2 = new Date(
    filterDate.getFullYear(),
    filterDate.getMonth() + 1
  );

  useEffect(() => {
    setTimeout(() => {
      if (timervar < 2) setTimerVar(timervar + 1);
    }, 2500);
    api
      .getAccountById(id)
      .then((result) => {
        // console.log(result);
        if (result) {
          setAccount(result);
        } else {
          setAccount(result);
          setLoading(() => false);
        }
      })
      .catch((error) => console.error(error));
  }, [timervar, id]);

  useEffect(() => {
    if (!loading) {
      loadingDialog?.close();
    }
  }, [loading]);

  useEffect(() => {
    if (account) {
      setLoading(true);
      api
        .getTxnsByAccountIdByDate(id, pseudoDate1, pseudoDate2)
        .then((results) => {
          setTxns(results);
        })
        .catch((error) => console.error(error));
      api
        .sumTillDateByAccount(pseudoDate1, id)
        .then((result) => {
          setOpenBalance(result);
        })
        .catch((error) => console.error(error));
      api
        .sumTillDateByAccount(pseudoDate2, id)
        .then((result) => {
          setCloseBalance(result);
        })
        .catch((error) => console.error(error));
      setLoading(false);
    }
  }, [filterDate, account]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(() => false);
    }, 5000);
    return () => clearTimeout(timer);
  }, []);

  const handleNewDate = (year, month) => {
    let theNewDate = new Date(year, month);
    setFilterDate(theNewDate);
  };

  const TxnsView = () => {
    return (
      <div className="">
        <div className="row bg-app text-white py-2">
          <div className="w-100 d-flex justify-content-between">
            <h3 className="text-center d-flex">
              {/* <i className="bi bi-person-circle mx-3" style={{ fontSize: "2rem", color: "white" }}></i> */}
              <img src={metrics.logo} height="40"></img>
              <div className="my-auto fs-smaller ms-2">
                {account ? account.get("name").substring(0, 20) : ""}
              </div>
            </h3>
            <MonthView onDateChange={handleNewDate} display={true} />
          </div>
        </div>
        <div className="background vw-100 public-view-scroll">
          <div className="row w-100 ps-1">
            <div
              align="center"
              className="text-success col-6 min_h_35 pb-5"
              id="cashin"
            >
              <i className="bi bi-arrow-down-circle-fill fs-3"></i>
              <span className="fs-3 text-uppercase px-3">
                <b>In</b>
              </span>
              <OpenBalance sum={openBalance} />
              <ListGroup variant="flush">
                {txns
                  ?.filter((txn) => txn.get("amount") > 0)
                  ?.map((txn, i) => {
                    if (!txn.cancelled) {
                      return <Txn data={txn} key={i} />;
                    }
                    return null;
                  })}
              </ListGroup>
            </div>
            <div
              align="center"
              className="text-danger col-6 min_h_35 pb-5"
              id="cashout"
            >
              <i className="bi bi-arrow-up-circle-fill fs-3"></i>
              <span className="fs-3 text-uppercase px-3">
                <b>Out</b>
              </span>
              <ListGroup variant="flush">
                {txns
                  ?.filter((txn) => txn.get("amount") < 0)
                  ?.map((txn, i) => {
                    if (!txn.cancelled) {
                      return <Txn data={txn} key={i} />;
                    }
                  })}
                <CloseBalance sum={closeBalance} />
              </ListGroup>
            </div>
          </div>
        </div>
        <div className="fixed-bottom w-100 bg-app py-2 px-3 text-white d-flex">
          <h3
            className="my-auto text-left text-md-left col-8"
            title="Account Balance"
          >
            Balance :{" "}
            {account
              ? accounting.formatMoney(Math.abs(account.get("balance")), "₹", 0)
              : ""}
          </h3>
          {account ? (
            <div className="ms-auto col-2 d-flex">
              <div className="col-6 ms-auto text-end w-100">
                <Dropdown>
                  <Dropdown.Toggle
                    className="btn btn-app btn-sm"
                    id="dropdown-basic"
                  >
                    <div className="d-flex">
                      {reportLoading ? (
                        <span className="mt-1 spinner-border text-white"></span>
                      ) : (
                        <i
                          className="bi bi-file-earmark-arrow-down"
                          style={{ fontSize: "2rem", color: "white" }}
                        ></i>
                      )}
                    </div>
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => handleDownload(1)}>
                      Current Month
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => handleDownload(3)}>
                      3 Month
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => handleDownload(6)}>
                      6 Month
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => handleDownload(12)}>
                      1 Year
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => handleDownload("lifetime")}>
                      Life Time
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              {/* <div className="col-6 d-none">
                <button className="btn btn-sm visually-hidden" disabled={true}><i className="bi bi-whatsapp" style={{ fontSize: "2rem", color: "white" }}></i></button>
              </div> */}
            </div>
          ) : null}
        </div>
      </div>
    );
  };

  const noAccountView = () => {
    return (
      <div className="background pt-5">
        <h3 className="text-center">No Account found !</h3>
        <h3 className="text-center">
          Go <a href="javascript:location.replace('/');">Home</a>
        </h3>
      </div>
    );
  };

  const loadingView = () => {
    loadingDialog?.showModal();
    return (
      <div className="background pt-5">
        <h3 className="text-center"></h3>
      </div>
    );
  };

  const handleDownload = (value) => {
    const endMonth = new Date();
    let startMonth = new Date();
    if (value === 1) {
      startMonth.setDate(1);
      setReportLoading(true);
    } else if (value === "lifetime") {
      startMonth = "lifetime";
    } else {
      startMonth.setMonth(endMonth.getMonth() - value);
      setReportLoading(true);
    }
    if (startMonth !== "lifetime") {
      startMonth.setDate(startMonth.getDate() - 1);
    }
    api.downloadMonthlyReport(id, startMonth, endMonth).then((res) => {
      if (res) {
        setReportLoading(false);
      }
    });
  };

  return (
    <>
      <dialog
        id="Loadingdialog"
        className="d-flex justify-content-center align-content-center text-app"
      >
        <div
          className="spinner-border spinner-border-sm my-auto me-2"
          role="status"
        ></div>{" "}
        <span className="fs-5">Loading</span>
      </dialog>
      {account
        ? loading
          ? loadingView()
          : TxnsView()
        : loading
        ? loadingView()
        : noAccountView()}
    </>
  );
}
export default Khata;
