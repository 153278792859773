import { useEffect, useState } from "react";
import { api  } from "services";
import { useParams, useNavigate } from "react-router-dom";

const Club = () => {
    const [ club, setClub] = useState(null);
    let { id } = useParams();

    useEffect(() => {
        console.log(id);
        api.getClubById(id)
        .then((_club) => {
            setClub(_club);
        }).then(() => {
            console.log(club.get("name"));
        }).catch((err) => console.log(err.message));
    }, []);

    //const club = props.data;
    //const members = club.get("member");
    const Member = (props) => {
        return (
            <>
                    <img src="https://via.placeholder.com/100X100" className="rounded-circle border border-5" />
                    <span className="member">Name #{props.element+1}</span>
            </>
        )
    }

    const people = () => {
        const rows = [];
        for(var i=0;i<club.get("member");i++){
            rows.push(<Member key={i} element={i} />);
        }
        return rows;
    }

    const Round = (props) => {
        return (
                <>
                    <img src="https://via.placeholder.com/50X50" className="rounded-circle border border-5" />
                    <span className="round">{props.element+1}</span>
                </>
        )
    }

    const rounds = () => {
        const rows = [];
        for(var i=0;i<club.get("member");i++){
            rows.push(<Round key={i} element={i} />);
        }
        return rows;
    }
    
    if(club == null) return null;

    return (
        <div align="center" className="pt-5">
          {people()}  
          <br /><br />
            <hr />
            <div className="row py-1">
                <div className="offset-md-3 col-md-3">
                    <h3 className=""><b>Club Amount <br />{Math.abs(club.get("amount")).toLocaleString('en-IN', {
                    maximumFractionDigits: 0,
                    style: 'currency',
                    currency: 'INR'
                    })}</b>
                    </h3>
                </div>
                <div className="col-md-3">
                    <h3 className=""><b>Contribution <br />{(club.get("amount")/club.get("member")).toLocaleString('en-IN', {
                    maximumFractionDigits: 0,
                    style: 'currency',
                    currency: 'INR'
                    })}/month</b></h3>
                </div>
            </div>
            <hr />
            <b>Rounds : </b>{rounds(club.get("member"))}  
        </div>
    )
}
/*
          
            
            */
export default Club;