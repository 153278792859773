import { useEffect, useState } from "react";
import { api } from "services";

const CacheMap = new Map()


export default function AccountName({ id }) {
  const [name, setName] = useState("");
  useEffect(() => {
    getAccountName(id).then((data) => {
      setName(data);
    });
  }, [id]);
  const getAccountName = async (id) => {
    if (!id) return;
    try {
      if (CacheMap.has(id)) {
        return CacheMap.get(id);
      }
      const res = await api.getAccountById(id);
      CacheMap.set(id, res.get("name"));
      return res.get("name");
    } catch (error) {
      console.log(error);
    }
  };

  

  return <>{name}</>;
}
