import { create } from "zustand";
import { api } from "services";

const useMyStore = create((set, get) => ({
    txns: [],
    accounts: [],
    updateAccountID: null,
    setUpdateAccountID: ((id) => {
        console.log(id);
        set({ updateAccountID: id });
    }),
    fetchAccounts: async () => {
        var _accounts = await api.getAccounts();
        set({accounts: _accounts});
    },
    addTxn: (txn) => set((state) => ({ txns: txns.push(txn) })),
    clearAllTxns: () => set({txns: []})
}))

export default useMyStore;