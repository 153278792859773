import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { auth } from "services";

function Settings() {
  const navigate = useNavigate();
  
  const goBack = () => {
    navigate(-1);
  }

  return (
    <div className="container">
      <div class="row mt-5">
        <div class="col-1">
          <a role="button" className="btn btn-app" onClick={goBack} title="Back">
            <i className="bi bi-arrow-left"></i>
          </a>
        </div>
        <div class="col-11">
          <h2 className="">Settings</h2>
        </div>
        <hr />
        <p>Coming Soon</p>
      </div>
    </div>
  )
}

export default Settings;
