import React from "react";
import 'animate.css';

export default function Dialog() {
    return (
        <div id="txn-success-dialog" className="d-none position-fixed start-50 end-50 bottom-50">
            <div className="m-auto animate__animated  animate__fadeOut animate__slow">
                <span className="my-auto ms-auto w-auto">
                    <i className="bi bi-patch-check-fill text-success my-auto text-start animate__animated animate__pulse"></i>
                </span>
            </div>
        </div>
    )
}