import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useOnboardingStore } from 'store/onboarding-store'
import { Rnd } from 'react-rnd'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

const OnboardingTasksModal = () => {
    const [showModal, setShowModal] = useState(true)
    const [x, setX] = useState(window.innerWidth - 420)
    const [y, setY] = useState(window.innerHeight - 500)
    const [opacity, setOpacity] = useState(0.85)
    const showOnboardingTasks = useOnboardingStore(
        (store) => store.showOnboardingTasks
    )

    const tasks = useOnboardingStore((state) => state.tasks)
    const startOnboardingTask = useOnboardingStore(
        (state) => state.startOnboardingTask
    )
    const router = useNavigate()

    if (!showOnboardingTasks) {
        return null
    }

    return (
        <Rnd
            position={{ x: x, y: y }}
            size={{ width: 400, height: 'auto' }}
            onDragStop={(e, d) => {
                setX(d.x)
                setY(d.y)
            }}
            style={{
                zIndex: 100000000,
                backgroundColor: 'white',
                padding: '1rem',
                borderRadius: '0.5rem',
                boxShadow: '0 0 1rem rgba(0,0,0,0.2)',
                opacity
            }}
            onMouseEnter={() => {
                setOpacity(1)
            }}
            onMouseLeave={() => {
                setOpacity(0.85)
            }}
        >
            <span
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}
            >
                <h5
                    style={{
                        fontStyle: 'bold',
                        paddingBottom: '0px'
                    }}
                >
                    Onboarding Tasks
                </h5>
                <FontAwesomeIcon
                    icon={showModal ? faMinus : faPlus}
                    className="btn btn-light rounded-circle"
                    size="xs"
                    onClick={() => {
                        setShowModal(!showModal)
                        if (!showModal) {
                            setX(window.innerWidth - 420)
                            setY(window.innerHeight - 450)
                            return
                        }
                        setX(window.innerWidth - 420)
                        setY(window.innerHeight - 50)
                    }}
                ></FontAwesomeIcon>
            </span>

            {showModal && (
                <>
                    <hr />
                    <div>
                        <ul className="list-group">
                            {tasks.map((task) => (
                                <span
                                    key={task.id}
                                    className={`alert ${
                                        task.completed
                                            ? 'text-muted alert-success'
                                            : 'list-group-item list-group-item-action'
                                    }`}
                                    style={{
                                        cursor: 'pointer'
                                    }}
                                    onClick={async () => {
                                        if (
                                            task.route &&
                                            typeof task.route === 'string'
                                        ) {
                                            router(task.route)
                                        } else if (
                                            task.route &&
                                            typeof task.route === 'function'
                                        ) {
                                            const route = await task.route()
                                            router(route)
                                        }

                                        startOnboardingTask(task.id)
                                    }}
                                >
                                    <div className={`d-flex`}>
                                        <input
                                            type="checkbox"
                                            checked={task.completed}
                                        />
                                        <span className="m-2">
                                            <strong>{task.title}</strong>
                                            <p className="mb-0">
                                                {task.description}
                                            </p>
                                        </span>
                                    </div>
                                </span>
                            ))}
                        </ul>
                    </div>
                </>
            )}
        </Rnd>
    )
}

export default OnboardingTasksModal
