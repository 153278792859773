const images = {
  logo: require('assets/images/logo.png'),
  background: require('assets/images/background.png'),
  India: require('assets/images/india.png'),
  Nepal: require('assets/images/nepal.png'),
  Bangladesh: require('assets/images/bangladesh.png'),
  Pakistan: require('assets/images/pakistan.png')
};

export default images;
