import Joyride from 'react-joyride'
import * as auth from '../services/auth.service'
import { Navigate, useLocation, useNavigate, useRoutes } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useOnboardingStore } from 'store/onboarding-store'
import { produce } from 'immer'
import { api } from 'services'
import { setOnboardingInfo } from '../services/auth.service'
import { toast } from 'sonner'

const user = auth.fetchUser()

const Onboard = () => {
    const run = useOnboardingStore((state) => state.run)
    const steps = useOnboardingStore((state) => state.steps)
    const tasks = useOnboardingStore((state) => state.tasks)
    const currentStep = useOnboardingStore((state) => state.currentStep)
    const writeOnboardingStore = useOnboardingStore(
        (state) => state.writeOnboardingStore
    )
    const currentTaskId = useOnboardingStore((state) => state.currentTaskId)
    const goToNextStep = useOnboardingStore((state) => state.goToNextStep)
    const onboardingInfo = useOnboardingStore((state) => state.onboardingInfo)

    const location = useLocation()
    const router = useNavigate()

    useEffect(() => {
        if (!run && currentTaskId && location.pathname.startsWith('/khata')) {
            writeOnboardingStore(
                produce((state) => {
                    state.run = true
                })
            )
        }
    }, [run, currentTaskId, location.pathname])

    const onboardingCompleted = async (taskId) => {
        writeOnboardingStore(
            produce((state) => {
                state.tasks = state.tasks.map((task) => {
                    if (task.id === state.currentTaskId) {
                        task.completed = true
                    }
                    return task
                })
                
                state.currentStep= 0
                state.steps= []
                state.run= false
                state.currentTaskId= null
         
                state.onboardingInfo = {
                    ...state.onboardingInfo,
                    [state.currentTaskId]: true
                }

                if (
                    state.tasks.filter((task) => !task.completed).length !== 0
                ) {
                    state.showOnboardingTasks = true
                }
            })
        )
        await setOnboardingInfo({
            ...onboardingInfo,
            [currentTaskId]: true
        })

        const toastMessageMap = {
            'new-account': 'Account created',
            'new-transaction': 'Transaction added',
            'new-cashbook': 'Cashbook created'
        }

        toast.success(toastMessageMap[currentTaskId])
    }


    const handleJoyrideCallback = async (data) => {
        console.log('data', data, currentTaskId)
        if (currentTaskId === 'new-account') {
            const { status, type } = data
            const finishedStatuses = ['finished', 'skipped']
            // debugger
            if ([1, 2, 3].includes(data.index)) return // these step is incremented in different place

            if (data.index === 4) {
                if (!finishedStatuses.includes(status)) {
                    return
                }
                await onboardingCompleted()
                const accounts = await api.getAllAccounts()
                const latestAccount = accounts.pop()
                console.log('latestAccount', latestAccount)
                router('/khata/' + latestAccount.id)
                return
            }

            if (type === 'step:after') {
                goToNextStep()
            }
        } else if (currentTaskId === 'new-transaction') {
            const { status, type } = data
            const finishedStatuses = ['finished', 'skipped']
            if ([0, 1, 2, 3].includes(data.index)) return // these step is incremented in different place

            if (type === 'step:after') {
                goToNextStep()
            }

            if (!finishedStatuses.includes(status)) {
                return
            }

            await onboardingCompleted()
        } else if (currentTaskId === 'new-cashbook') {
            const { status, type } = data
            const finishedStatuses = ['finished', 'skipped']
            if ([0, 1, 2, 3, 4].includes(data.index)) return // these step is incremented in different place

            if (type === 'step:after') {
                goToNextStep()
            }

            if (!finishedStatuses.includes(status)) {
                return
            }

            await onboardingCompleted()
        }
    }

    if (!user) {
        return <Navigate to="/signup" />
    }

    if (location.pathname === '/signup' || location.pathname === '/login') {
        return <></>
    }

    return run ? (
        <Joyride
            callback={handleJoyrideCallback}
            continuous
            hideCloseButton
            run={run}
            scrollToFirstStep
            showProgress
            showSkipButton
            steps={steps}
            stepIndex={currentStep}
            styles={{
                options: {
                    zIndex: 10000
                }
            }}
        />
    ) : null
}

export default Onboard
