import { createSlice } from "@reduxjs/toolkit";

const initialValues = {
  allRcTransctions: null,
};

const rctransctionSlice = createSlice({
  name: "rctransction",
  initialState: initialValues,
  reducers: {
    getRCTransctions: (state, action) => {
      state.allRcTransctions = action.payload;
    },
  },
});

export default rctransctionSlice;
