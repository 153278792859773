import { createSlice } from "@reduxjs/toolkit";

const initialValues = {
  allAccounts: null,
  accountBalance: 0,
  filteredAcc: null,
};

const accountSlice = createSlice({
  name: "accounts",
  initialState: initialValues,
  reducers: {
    getReduxAccounts: (state, action) => {
      state.allAccounts = action.payload;
    },
    setAccountBalance: (state, action) => {
      state.accountBalance = action.payload;
    },
    setFilteredAcc: (state, action) => {
      state.filteredAcc = action.payload;
    },
  },
});

export default accountSlice;
