import { useState } from "react";
import { Form, Offcanvas } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import DatePicker from "react-date-picker";
import { api } from "services";
import useUniversalStore from "store/universalStore";

function Reminder(props) {
  const { account, id, currentSetDate } = props;
  const [show, setShow] = useState(false);
  const [edit, setEdit] = useState(false);
  const [values, setValues] = useState({
    errormsg: "",
    successmsg: "",
    loading: false,
  });
  const enabled = account.get("enabled");
  const {updateRefresh} = useUniversalStore((state) => state.updateRefresh);
  const minDate = new Date();
  minDate.setDate(minDate.getDate() + 1);
  minDate.setHours(0, 0, 0, 0);
  const [information, setInformation] = useState({
    date: minDate,
  });
  const overDue = currentSetDate?.getTime() <= new Date().getTime();
  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => setShow(true);
  const handleChangeDate = (e) => {
    let date = e;
    setInformation((prev) => {
      let tempValue = { ...prev };
      tempValue.date = date;
      return tempValue;
    });
  };

  const handleSave = (e) => {
    e.preventDefault();
    handleLoading(true);
    console.log(information.date);
    api
      .AddReminder(id, information.date, true)
      .then((res) => {
        handleLoading(false);
        setEdit(() => false);
        success("Rminder scheduled successfully!");
        updateRefresh("account");
      })
      .catch((err) => err);
  };

  const handleToggle = (e) => {
    e.preventDefault();
    const now = new Date().getTime();
    handleLoading(true);
    if (currentSetDate) {
      if (currentSetDate.getTime() > now) {
        api.toggleReminder(id)
          .then((res) => {
            handleLoading(false);
            updateRefresh("account");
          })
          .catch((err) => err);
      } else {
        if (enabled)
          api.toggleReminder(id)
            .then((res) => {
              handleLoading(false);
              updateRefresh("account");
            })
            .catch((err) => err);
        else failure("Please select date after today!");
      }
    } else {
      failure("Set date to turn on the reminder!");
    }
  };

  const handleDelete = () => {
    handleLoading(true);
    api.AddReminder(id, null, false)
      .then((res) => {
        handleLoading(false);
        success("Reminder has been removed successfully!");
        updateRefresh("account");
      })
      .catch((err) => {
        handleLoading(false);
        console.error(err.message);
        failure(err.message);
      });
  };

  const handleLoading = (state) => {
    setValues({
      errormsg: "",
      successmsg: "",
      loading: state,
    });
  };

  const failure = (msg) => {
    setValues({
      errormsg: msg,
      successmsg: "",
      loading: "",
    });
    setTimeout(() => {
      setValues({
        errormsg: "",
        successmsg: "",
        loading: false,
      });
    }, 2500);
  };

  const success = (msg) => {
    setValues({
      errormsg: "",
      successmsg: msg,
      loading: "",
    });
    setTimeout(() => {
      setValues({
        errormsg: "",
        successmsg: "",
        loading: false,
      });
    }, 2500);
  };

  const editHandle = (date) => {
    handleChangeDate(date);
    setEdit(() => true);
  };

  return (
    <>
      <Button
        className=" btn btn-danger rounded"
        onClick={handleShow}
        title="Set reminder Date"
      >
        <i
          className="bi bi-alarm"
          style={{ fontSize: "2rem", color: "white" }}
        ></i>
      </Button>

      <Offcanvas show={show} onHide={handleClose} placement="end">
        <Offcanvas.Header className="bg-danger text-light">
          <h3 className="offcanvas-title">
            <i className="bi bi-alarm" style={{ fontSize: "2rem" }}></i> Set
            Reminder
          </h3>
          <button
            type="button"
            className="btn-close text-reset bg-light"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            onClick={handleClose}
          ></button>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {!currentSetDate || !enabled || edit ? (
            <div className="animate__animated animate__fadeIn">
              <div className="mb-1">
                <Form.Label className="display-6 py-2">
                  When do you want to be Reminded ?
                </Form.Label>
                <DatePicker
                  value={information.date}
                  onChange={handleChangeDate}
                  minDate={minDate}
                  calendarIcon={null}
                  clearIcon={null}
                  format="dd/MM/yy"
                  className="border border-2 border-secondary rounded py-3 h5 fw-bold"
                />
              </div>
              <div className="row my-3">
                <div className="col-md-6 d-grid mt-md-0">
                  <button
                    type="cancel"
                    className="btn btn-outline-dark btn-block"
                    data-bs-dismiss="offcanvas"
                    onClick={() => (edit ? setEdit(false) : handleClose())}
                  >
                    <b className="fs-5">Cancel</b>
                  </button>
                </div>
                <div className="col-md-6 d-grid mt-md-0">
                  <button
                    type="submit"
                    className="btn btn-danger block py-3"
                    data-bs-dismiss="offcanvas"
                    onClick={handleSave}
                  >
                    <b className="fs-5">Schedule</b>
                  </button>
                </div>
              </div>
              <br />
            </div>
          ) : (
            <Form.Text muted className="mb-2">
              <div className="alert alert-danger">
                <h4 className="display-6 text-center">Current Reminder</h4>
                {overDue ? (
                  <div className="text-danger text-center h6">
                    <i className="bi bi-patch-exclamation"></i> Payment Overdue
                  </div>
                ) : null}
              </div>
              <div className="row">
                <ul className="list-group list-group-horizontal col-12">
                  <li
                    className={
                      overDue
                        ? "list-group-item col-10"
                        : "list-group-item col-8"
                    }
                  >
                    <h3
                      className={
                        enabled
                          ? "text-danger text-center py-3"
                          : "text-center py-3"
                      }
                    >
                      {currentSetDate ? (
                        <i className="bi bi-calendar">
                          {" "}
                          {currentSetDate.toLocaleDateString()}{" "}
                        </i>
                      ) : (
                        "N/A"
                      )}
                    </h3>
                  </li>
                  <li
                    className={
                      overDue ? "d-none" : "list-group-item col-2 d-flex"
                    }
                  >
                    <h3 className="my-auto">
                      <i
                        className="bi bi-pencil-square my-auto"
                        onClick={(e) => editHandle(currentSetDate)}
                        type="button"
                        title="Edit Reminder"
                      ></i>
                    </h3>
                  </li>
                  <li className="list-group-item col-2 d-flex">
                    <h3 className="my-auto">
                      {overDue ? (
                        <i
                          className="bi bi-trash my-auto"
                          onClick={handleDelete}
                          type="button"
                          title="Delete Reminder"
                        ></i>
                      ) : (
                        <i
                          className="bi bi-toggle2-on my-auto"
                          onClick={handleToggle}
                          type="button"
                          title="Disable Reminder"
                        ></i>
                      )}
                    </h3>
                  </li>
                </ul>
              </div>
            </Form.Text>
          )}
          {values.errormsg ? (
            <div className="form-group text-start animate__animated animate__pulse">
              <div
                className="form-check-label alert alert-danger text-capitalized"
                id="loginerror"
              >
                <i className="bi bi-exclamation-circle-fill"></i>{" "}
                {values.errormsg}
              </div>
            </div>
          ) : (
            <></>
          )}
          {values.successmsg ? (
            <div className="form-group text-start">
              <div
                className="form-check-label alert alert-success text-capitalized"
                role="alert"
              >
                <i className="bi bi-check-circle-fill"></i> {values.successmsg}
              </div>
            </div>
          ) : (
            <></>
          )}
          {values.loading ? (
            <div className="form-group text-start">
              <div
                className="form-check-label alert alert-warning text-capitalized "
                role="alert"
              >
                <span className="spinner-border spinner-border-sm"></span>{" "}
                Loading...
              </div>
            </div>
          ) : (
            <></>
          )}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default Reminder;
