import React, { useState } from "react";
import { ListGroup, Row, Col } from "react-bootstrap";

function OpenBalance(props){
  return(
    <ListGroup.Item className="bg-transparent border-bottom text-dark">
      <div className="row py-2 px-0">
        <div className="col-4 p-0 text-end pe-2">
        <strong className="fs-xmd-smaller fs-6"><i>
          {props.sum.toLocaleString('en-IN', {
          maximumFractionDigits: 0,
          style: 'currency',
          currency: 'INR'
          })}
          </i></strong>
        </div>
        <div className='col-8 text-start p-0'>
          <b className="fs-xmd-smaller fs-5">Open Balance</b>
        </div>
      </div>
    </ListGroup.Item>
  )
}

export default OpenBalance;
