import React, { useEffect, useState } from "react";
import { FaFilePdf } from "react-icons/fa";
const { forwardRef, useRef, useImperativeHandle } = React;

const FilePicker = forwardRef((props, ref) => {
  const [upload, setUpload] = useState({ state: false, file: null });
  const [values, setValues] = useState({
    errormsg: "",
    preview: null,
  });

  useEffect(() => {
    if (!upload) {
      setValues({
        preview: null,
        errormsg: "",
      });
    }
  }, [values.preview]);

  useImperativeHandle(ref, () => ({
    removePhoto() {
      setUpload({ ...upload, state: false });
      document.getElementById("upload_bills").value = "";
      failure("");
    },
  }));

  const removeUpload = (e) => {
    e.preventDefault();
    setUpload({ ...upload, state: false });
    document.getElementById("upload_bills").value = "";
    failure("");
  };

  const setforupload = (e) => {
    e.preventDefault();
    setUpload({ ...upload, state: true, file: e.target.files[0] });
    const file = e.target.files[0];
    if (file.size <= 10485760 && e.target.files.length <= 1) {
      const objectUrl = URL.createObjectURL(file);
      success(objectUrl);
      props.onPick(file);
    } else if (e.target.files.length > 1) {
      failure("Only one file allowed");
    } else {
      document.getElementById("upload_bills").value = "";
      failure("File exceeds the size limit of 10MB.");
    }
  };

  const success = (objectUrl) => {
    setValues({
      errormsg: "",
      preview: objectUrl,
    });
  };

  const failure = (message) => {
    setValues({
      ...values,
      errormsg: message,
      preview: null,
    });

    setTimeout(() => {
      setValues({
        ...values,
        errormsg: "",
      });
    }, 5000);
  };

  return (
    <div className="upload_bill_container">
      <input
        type="file"
        id="upload_bills"
        className="d-none"
        onChange={(e) => setforupload(e)}
        accept={["image/*", "application/pdf"]}
      />
      <label htmlFor="upload_bills" className="btn btn-outline-dark">
        <span className="my-auto">Upload Bill </span>
        <i className="bi bi-paperclip"></i>
      </label>
      <br />
      {values.preview ? (
        <span className="position-relative">
          {upload.file.name?.includes(".pdf") ? (
            <FaFilePdf
              size={200}
              className="ms-2 text-danger text-decoration-none pe-none "
              title="Download PDF"
            />
          ) : (
            <img
              src={values.preview}
              className="uploadbillpreview mt-2 rounded position-relative"
            />
          )}

          <a onClick={removeUpload}
            className="btn btn-light rounded-circle position-absolute end-0 upload-preview-remover">
            <i className="bi bi-x-lg"></i>
          </a>
        </span>
      ) : null}
      {values.errormsg ? (
        <div className="form-group text-start my-2">
          <div
            className="form-check-label alert alert-danger text-capitalized py-2"
            id="newacerror"
          >
            <i className="bi bi-exclamation-circle-fill"></i> {values.errormsg}
          </div>
        </div>
      ) : null}
    </div>
  );
});

export default FilePicker;
