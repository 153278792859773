import { useState, useEffect, useRef, useCallback } from "react";
import { ListGroup, Col, Dropdown } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { faPencil } from "@fortawesome/free-solid-svg-icons";
import { api, auth } from "services";
import Txn from "./Txn";
import {
  OpenBalance, CloseBalance, Reminder, ExpandableButton, ConfirmDelete
} from "components";
import MonthView from "./MonthView";
import ReminderAlert from "alerts/ReminderAlert";
import RecursivetxnsView from "Forms/Recursive/RecursivetxnsView";
import { useOnboardingStore } from "store/onboarding-store";
import useUniversalStore from "store/universalStore";
import "animate.css/animate.min.css";

const accounting = require("accounting");

const Khata = () => {
  const { id } = useParams();
  const headerRef = useRef(null);
  const navBarRef = useRef(document.getElementById("main-nav"));
  const bottomNav = useRef(null);
  const [headerHeight, setHeaderHeight] = useState(225);

  const [txns, setTxns] = useState([]);
  const [account, setAccount] = useState(null);
  const [filterDate, setFilterDate] = useState(new Date());
  const [openBalance, setOpenBalance] = useState(0);
  const [closeBalance, setCloseBalance] = useState(0);
  const [balance, setBalance] = useState(0);
  const [loading, setLoading] = useState(true);
  const [reportLoading, setReportLoading] = useState(false);
  const [showReminder, setShowReminder] = useState(false);
  const [animation, setAnimation] = useState("");
  const accounts = useUniversalStore((state) => state.accounts);
  const transactionRefresh = useUniversalStore(
    (state) => state.refresh.transactionRefresh
  );

  const { write, updateTranscation } = useUniversalStore();

  const [allTheTransaction, setAllTheTranscation] = useState([]);
  const currentUser = auth.fetchUser();
  const currentDate = new Date();
  const pseudoDate1 = new Date(
    filterDate.getFullYear(),
    filterDate.getMonth(),
    0,
    23,
    59,
    59,
    999
  );
  const pseudoDate2 = new Date(
    filterDate.getFullYear(),
    filterDate.getMonth() + 1
  );

  const goToNextStep = useOnboardingStore((state) => state.goToNextStep);
  //   console.log(account);
  const overDue =
    currentDate >= account?.get("date") && account?.get("enabled");
  
  useEffect(() => {
    api.getAccountById(id).then((res) => {
      setAccount(res);
    });
    api.getTxnsByAccountIdByDate(id, pseudoDate1, pseudoDate2).then((res) => {
      //console.log(res);
    });
  }, [id]);

  useEffect(() => {
    if (!account) return;
    api.getTxnsByAccountId(account.id).then((res) => {
      setAllTheTranscation(res);
    });
  }, [account]);

  useEffect(() => {
    getOpenKhata();
  }, [accounts]);

  useEffect(() => {
    if (!navBarRef.current) {
      navBarRef.current = document.getElementById("main-nav");
    }

    // console.log('headerRef.current', headerRef.current, navBarRef.current)
    if (headerRef.current && navBarRef.current && bottomNav.current) {
      setHeaderHeight(
        headerRef.current.clientHeight +
          navBarRef.current.clientHeight +
          bottomNav.current.clientHeight
      );
    }
  }, [
    headerRef.current?.clientHeight,
    navBarRef.current?.clientHeight,
    bottomNav.current?.clientHeight,
  ]);

  useEffect(() => {
    if (overDue) {
      setShowReminder((prev) => true);
    } else {
      setShowReminder((prev) => false);
    }
  }, [overDue]);

  useEffect(() => {
    if (!account) return;
    let opensum = 0;
    let closesum = 0;
    let overallBalance = 0;
    let trans1 = [];

    const userObjectId = account.id;
    allTheTransaction?.map((transaction) => {
      const Date = transaction.get("date");
      if (
        transaction.get("accountId")?.id === userObjectId &&
        transaction.get("cancelled") === false
      ) {
        overallBalance += transaction.get("amount");
      }
      if (
        transaction.get("accountId")?.id === userObjectId &&
        Date > pseudoDate1 &&
        Date < pseudoDate2
      ) {
        trans1.push(transaction);
      }
      if (
        transaction.get("accountId")?.id === userObjectId &&
        Date < pseudoDate1 &&
        transaction.get("cancelled") === false
      ) {
        opensum += transaction.get("amount");
      }
      if (
        transaction.get("accountId")?.id === userObjectId &&
        Date < pseudoDate2 &&
        transaction.get("cancelled") === false
      ) {
        closesum += transaction.get("amount");
      }
    });
    setTxns(trans1);
    setOpenBalance(opensum);
    setCloseBalance(closesum);
    setBalance(overallBalance);
    // checkBalance(overallBalance);
    // allExec()
    setLoading(() => false);
  }, [filterDate, account, allTheTransaction, transactionRefresh]);

  // useEffect(() => {
  //   if (!account) return;
  //   checkBalance(balance);
  // }, [balance]);
  const getOpenKhata = () => {
    if (accounts.length === 0) return;
    return accounts.map((account) => {
      if (account.id === id) {
        setLoading(() => false);
        setAccount(account);
        return account;
      }
    });
  };

  const checkBalance = (calculatedBalance) => {
    const prevBalance = account.get("balance");
    if (prevBalance !== calculatedBalance) {
      const errorAmount = calculatedBalance - prevBalance;
      api.updateBalance(account.id, errorAmount).then((newAc) => {
        // updateAccountsState(newAc);
      });
    }
  };

  const updateAccountsState = useCallback(
    (newAc) => {
      const arrayIndex = accounts.findIndex((obj) => obj.id === newAc.id);
      const updatedAccounts = [...accounts];
      updatedAccounts[arrayIndex] = newAc;
      write({ accounts: updatedAccounts });
    },
    [accounts]
  );

  const noAccountView = () => {
    return (
      <div className="background pt-5">
        <h3 className="text-center">No Account found !</h3>
      </div>
    );
  };

  const handleDownload = (value) => {
    const endMonth = new Date();
    let startMonth = new Date();
    startMonth.setHours(0, 0, 0, 0);
    if (value === 1) {
      startMonth.setDate(1);
      setReportLoading(true);
    } else if (value === "lifetime") {
      setReportLoading(true);
      startMonth = "lifetime";
    } else {
      startMonth.setMonth(endMonth.getMonth() - value);
      setReportLoading(true);
    }
    // console.log(startMonth, endMonth);
    api.downloadMonthlyReport(id, startMonth, endMonth).then((res) => {
      setReportLoading(false);
      if (res) {
        // null
      } else {
        alert(
          "Something went wrong! Could not download report. Please try again later."
        );
      }
    });
  };

  const reportsView = () => {
    return (
      <Dropdown>
        <Dropdown.Toggle
          className="btn btn-danger btn-sm"
          id="dropdown-basic"
          title="Reports"
        >
          <div className="d-flex">
            {reportLoading ? (
              <span className="mt-1 spinner-border text-white"></span>
            ) : (
              <i
                className="bi bi-file-earmark-arrow-down"
                style={{ fontSize: "2rem", color: "white" }}
              ></i>
            )}
          </div>
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item onClick={() => handleDownload(1)}>
            Current Month
          </Dropdown.Item>
          <Dropdown.Item onClick={() => handleDownload(3)}>
            3 Month
          </Dropdown.Item>
          <Dropdown.Item onClick={() => handleDownload(6)}>
            6 Month
          </Dropdown.Item>
          <Dropdown.Item onClick={() => handleDownload(12)}>
            1 Year
          </Dropdown.Item>
          <Dropdown.Item onClick={() => handleDownload("lifetime")}>
            Life Time
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const header = () => {
    return (
      <div
        className="bg-app text-white py-2"
        ref={headerRef}
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <div
          role="button"
          onClick={() => {
            write({ accountId: account.id });
          }}
          data-bs-toggle="offcanvas"
          data-bs-target="#editAccDrawer"
          aria-controls="editAccDrawer"
        >
          <h3 className="text-left d-flex">
            {account?.get("profilePhoto") ? (
              <div className="profile-img">
                <img
                  src={account.get("profilePhoto")._url}
                  alt="Profile Image"
                  className="img-fluid"
                />
              </div>
            ) : (
              <i
                className="bi bi-person-circle mx-3"
                style={{ fontSize: "45px", color: "white" }}
              ></i>
            )}

            <div className="d-flex flex-column my-auto me-md-auto">
              {account ? account.get("name").substring(0, 30) : ""}
              <span style={{ fontSize: "14px" }}>
                click her for contact info
              </span>
            </div>
          </h3>
        </div>
        <span>
          <MonthView
            setAnimation={setAnimation}
            onDateChange={handleNewDate}
            display={true}
          />
        </span>
      </div>
    );
  };

  const loadingView = () => {
    // loadingDialog?.showModal();
    return (
      <div className="background pt-5">
        <h3 className="text-center"></h3>
      </div>
    );
  };

  const edittxn = (data) => {
    updateTranscation(data);
  };

  const confirmit = (txn) => {
    write((prev) => {
      let tempVar = { ...prev.deleteTransactions };
      tempVar.type = "Transaction";
      tempVar.object = txn;
      return { ...prev, deleteTransactions: tempVar };
    });
    const toDel = { delTxn: txn };
    
    // setDeletionOf(txn);
  };

  const handleNewDate = (year, month) => {
    let theNewDate = new Date(year, month);
    setFilterDate(theNewDate);
  };

  const TxnsView = () => {
    return (
      <div
        style={{
          overflowY: "scroll",
          zIndex: -100,
        }}
      >
        <div
          className={`background h-auto mb-auto pb-5 overflow-hidden ${
            showReminder ? "opacity-25" : ""
          } scrollbar-design slide ${animation}`}
          onAnimationEnd={() => setAnimation("")}
        >
          <div className="row">
            <Col md={6} align="center" className="text-success">
              <i className="bi bi-arrow-down-circle-fill fs-3"></i>
              <span className="fs-3 text-uppercase px-3">
                <b>In</b>
              </span>
            </Col>
            <Col md={6} align="center" className="text-danger">
              <i className="bi bi-arrow-up-circle-fill fs-3"></i>
              <span className="fs-3 text-uppercase px-3">
                <b>Out</b>
              </span>
            </Col>
          </div>

          <div className="row">
            <div className="col-md-6">
              <OpenBalance sum={openBalance} />
              <ListGroup variant="flush">
                {txns
                  ?.filter((txn) => txn.get("amount") > 0)
                  ?.map((txn, i) => {
                    if (!txn.cancelled) {
                      return (
                        <Txn
                          data={txn}
                          key={i}
                          onEdit={() => edittxn(txn)}
                          onDel={confirmit}
                        />
                      );
                    }
                    return null;
                  })}
              </ListGroup>
            </div>
            <div className="col-md-6">
              <ListGroup variant="flush">
                {txns
                  ?.filter((txn) => txn.get("amount") < 0)
                  ?.map((txn, i) => {
                    if (!txn.cancelled) {
                      return (
                        <Txn
                          data={txn}
                          key={i}
                          onEdit={() => edittxn(txn)}
                          onDel={confirmit}
                        />
                      );
                    }
                  })}
                <CloseBalance sum={closeBalance} />
              </ListGroup>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const remind = () => {
    // alert('reminder');
    const num = account.get("phone");
    const name = currentUser.get("name");
    if (account.get("balance") < 0) {
      const bal = -account.get("balance");
      var line1 = "Payment Reminder : ";
      var line2 = `Balance *₹${bal}* due to *${name}*`;
    } else {
      const bal = account.get("balance");
      var line1 = "Payment details : ";
      var line2 = `Balance *₹${bal}* advance to *${name}*`;
    }
    const link = "Ledger : https://web.bahikhata.org/p/" + id;
    const line3 = `Sent via BahiKhata App`;
    window.open(
      `https://wa.me/${num}?text=${line1}%0a${line2}%0a${link}%0a%0a${line3}`,
      "_blank"
    );
  };

  const subMenu = () => (
    <div className="col-4 d-flex ms-auto" align="right">
      <div className="col-3 d-flex" align="right">
        <Reminder
          account={account}
          id={id}
          currentSetDate={account.get("date")}
        />
      </div>
      <div className="col-3 d-flex" align="right">
        <RecursivetxnsView account={account} getOpenKhata={getOpenKhata} />
      </div>
      <div className="col-3 d-flex" align="right">
        {reportsView()}
      </div>
      <div className="col-3 d-flex" align="right">
        <button
          className="btn btn-sm bg-app rounded"
          title="Remind"
          onClick={remind}
        >
          <i
            className="bi bi-whatsapp"
            style={{
              fontSize: "2rem",
              color: "white",
            }}
          ></i>
        </button>
      </div>
    </div>
  );

  return (
    <>
      <div className="container-fluid px-0">
        {header()}

        {account ? loading ? loadingView() : TxnsView()
          : loading ? loadingView() : noAccountView()}

        <div className={
            currentUser
              ? "row position-fixed bottom-0 end-0 my-5 py-5 px-4 w-auto newentry-btn"
              : "d-none"
        }>
          <ExpandableButton
            title="New Entry"
            clickForm="addTxnDrawer"
            onClick={(e) => goToNextStep()}
            icon={faPencil} />
        </div>
        <ReminderAlert
          currentUser={currentUser}
          account={account}
          id={id}
          setShowReminder={setShowReminder}
          showReminder={showReminder}
          updateFunction={updateAccountsState}
        />
        <div
          className="sticky-bottom bg-app py-3 px-3 text-white d-flex"
          ref={bottomNav}
        >
          <h3
            className="my-auto text-left text-md-left"
            title="Account Balance"
          >
            Balance :{" "}
            {account ? accounting.formatMoney(Math.abs(balance), "₹", 0) : ""}
          </h3>
          {account ? subMenu() : null}
        </div>
      </div>
      <ConfirmDelete />
    </>
  );
};

export default Khata;
