const Status = (props) => {
    var { type, message } = props;
    
    if(type == null){
        return <div className="py-2"></div>;
    }
    else if(type == "error"){
        return (
            <div className="form-group text-start animate__animated animate__pulse">
            {console.log("its danger")}

                <div className="form-check-label alert alert-danger text-capitalized" role="alert">
                <i className="bi bi-exclamation-circle-fill"></i> {message}
                </div>
            </div>
        );
    }
    return (
        <div className="form-group text-start">
            {console.log("its success")}
            <div className="form-check-label alert alert-success text-capitalized" role="alert">
                <i className="bi bi-check-circle-fill"></i>{' '}{message}
            </div>
        </div>
    )
}

export default Status;