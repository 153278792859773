import React, {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useMemo,
  useCallback,
} from "react";
import DatePicker from "react-date-picker";
import { auth, api, sms } from "services";
import txnaudio from "assets/sounds/google_pay-mini.mp3";
import FilePicker from "../views/FilePicker";
import Creatable from "react-select/creatable";
import { useOnboardingStore } from "store/onboarding-store";
import { debounce } from "utils";
import { useLocation } from "react-router-dom";
import useUniversalStore from "store/universalStore";

const OneTimeTxn = (props) => {
  const { openKhata, getOpenKhata, allAccounts, khataview } = props;
  const [multipler, setMultipler] = useState(1);
  const [date, onDateChange] = useState(new Date());
  const [file, setFile] = useState(null);
  const [notify, setNotify] = useState(false);
  const [values, setValues] = useState({
    amount: "",
    account_name: "",
    details: "",
    errormsg: "",
  });
  const [options, setOptions] = useState([]);
  const childRef = useRef();
  const selectRef = useRef();
  const user = auth.fetchUser();
  const goToNextStep = useOnboardingStore((state) => state.goToNextStep);
  const currentTaskId = useOnboardingStore((state) => state.currentTaskId);
  const goToNextStepDebounced = useMemo(
    () => debounce(goToNextStep, 3000),
    [goToNextStep]
  );
  const currentStep = useOnboardingStore((state) => state.currentStep);
  const formatter = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    minimumFractionDigits: 0,
  });
  const { updateRefresh } = useUniversalStore();
  // const trxDrawerRef = useRef(document.getElementById('addTxnDrawer'))
  const { pathname } = useLocation();
  // console.log('pathname', pathname)

  useEffect(() => {
    if (allAccounts) {
      const activeAc = allAccounts.filter((ac) => !ac.get("cancelled"));
      populateAccountsDropdown(activeAc);
    }
  }, [allAccounts]);

  useEffect(() => {
    if (values.account_name === "") {
      setNotify((val) => false);
    }
  }, [values.account_name]);
  const initForm = () => {
    // console.log('initForm')

    if (!pathname.includes("khata/")) {
      accountChangeHandler({ value: "" });
    } else if (openKhata?.id) {
      if (openKhata.get("phone")) {
        setNotify(true);
      } else {
        setNotify(false);
      }
      if (openKhata.id === pathname.split("khata/")[1]) {
        accountChangeHandler({ value: openKhata.get("name") });
      } else {
        accountChangeHandler({ value: "Loading..." });
        getOpenKhata();
      }
    } else {
      getOpenKhata();
    }
  };

  useEffect(() => {
    initForm();
  }, [openKhata, pathname]);

  const resetForm = useCallback(() => {
    setValues({
      amount: "",
      account_name: "",
      details: "",
    });
    onDateChange(new Date());
  }, []);

  document
    .getElementById("addTxnDrawer")
    ?.addEventListener("hide.bs.offcanvas", function (e) {
      resetForm();
    });

  document
    .getElementById("addTxnDrawer")
    ?.addEventListener("show.bs.offcanvas", function (e) {
      initForm();
    });

  const failure = (msg) => {
    setValues({
      ...values,
      errormsg: msg,
    });
    setTimeout(() => {
      setValues({
        ...values,
        errormsg: "",
      });
    }, 4000);
  };

  const amountChangeHandler = (e) => {
    e.preventDefault();
    goToNextStepDebounced();
    let amountValue = e.target.value.replace(/[₹,]/g, "");
    setValues((prev) => {
      let tempValue = { ...prev };
      if (amountValue === NaN || amountValue === "") {
        tempValue.amount = 0;
        return tempValue;
      } else if (amountValue <= 10000000) {
        tempValue.amount = parseFloat(amountValue.replace(/[^0-9.-]/g, ""));
        return tempValue;
      } else {
        failure("Transfering More than 1cr is Restricted");
        return tempValue;
      }
    });
  };

  let formattedValue;
  if (values.amount !== null) {
    formattedValue = formatter.format(values.amount);
  }
  const detailsChangeHandler = (e) => {
    e.preventDefault();
    goToNextStepDebounced();
    setValues({
      ...values,
      details: e.target.value,
    });
  };

  const accountChangeHandler = (e) => {
    if (e?.value && currentTaskId === "new-cashbook" && currentStep === 2) {
      goToNextStepDebounced();
    }
    setValues({
      ...values,
      account_name: e ? e.value : "",
    });
  };

  useEffect(() => {
    if (window.location.href.includes("khata/") && !values.account_name) {
      getOpenKhata();
    }
  }, [window.location.href]);

  const accountContainsNum = () => {
    const acname = values.account_name;
    const temp = allAccounts.filter((acc, index) => acc.get("name") === acname);
    const tempAccount = temp[0];
    if (tempAccount.get("phone")) {
      return true;
    }
    return false;
  };

  const notifyChangeHandler = (e) => {
    goToNextStepDebounced();
    if (values.account_name) {
      if (accountContainsNum()) {
        setNotify((value) => !value);
      } else {
        failure("Selected Account do not have a number associated to it");
      }
    } else {
      setNotify(() => false);
      failure("Select an account to notify");
    }
  };

  // const fetchBillUntilFound = (txnId) => {
  //     const myInterval = setInterval(() => {
  //         api.getTransactionById(txnId).then((txn) => {
  //             if (txn.get("attachment") !== "https://bahikhatabills.s3.amazonaws.com/cb97925b28803a604429df0b4fb43f6f_download1.png") {
  //                 // console.log("yeessssssssss");
  //                 // updateStates(false, false, txn);
  //                 clearInterval(myInterval);
  //             }
  //         })
  //     }, 1000);
  // }

  const addTxn = async (e) => {
    e.preventDefault();

    if (values.amount > 0 && values.amount <= 10000000) {
      const dialog = document.getElementById("Loadingdialog");
      dialog.showModal();
      const aa = new Date();
      date.setHours(aa.getHours(), aa.getMinutes(), aa.getSeconds());

      try {
        const result = await api.transaction(
          values.account_name,
          values.amount * multipler,
          values.details,
          file,
          date
        );
        if (
          result.get("accountId") &&
          result.get("accountId").get("phone") !== "" &&
          notify
        ) {
          if (result.get("amount") < 0) {
            sms
              .sendPaidSMS(
                "Rs. " + Math.abs(result.get("amount")),
                user.get("name") ? user.get("name") : user.get("phone"),
                result.get("accountId").id,
                91 + result.get("accountId").get("phone")
              )
              .then((res) => {
                //console.log(res);
              });
          } else {
            sms
              .sendReceiveSMS(
                "Rs. " + Math.abs(result.get("amount")),
                user.get("name") ? user.get("name") : user.get("phone"),
                result.get("accountId").id,
                91 + result.get("accountId").get("phone")
              )
              .then((res) => {
                //console.log(res);
              });
          }
        }

        // Clear the form

        updateRefresh("account");
        updateRefresh("transaction");
        resetForm();
        dialog.close();
        // props.onTriggerTxns();
        setFile(() => null);
        childRef.current.removePhoto();
        successaudio();
        successpopup();
        // setNotify(true);
      } catch (error) {
        console.log("Error while adding transaction: ", error);
      }
    } else {
      failure("Enter an amount between 0 and 99,99,999₹.");
    }
  };

  // const updateTransactionsState = (newTxn) => {
  //     console.log(newTxn);
  //     const arr = copiedTransactions.concat([newTxn]);
  //     // copiedTransactions.push(newTxn);
  //     dispatch(getTransctions(arr));
  // }

  // const updateAccountsState = (newAc) => {
  //     const arrayIndex = allAccounts.findIndex(obj => obj.id == newAc.id);
  //     // console.log(arrayIndex);
  //     allAccounts[arrayIndex] = newAc;
  //     dispatch(getReduxAccounts(allAccounts));
  // }

  const fileFetched = (file) => {
    // console.log(file);
    setFile(file);
  };

  const successaudio = () => {
    var audio = new Audio(txnaudio);
    audio.play();
  };

  const successpopup = () => {
    const successdialog = document.getElementById("successdialog");
    // Show Success popup
    successdialog.showModal();
    // Hide Success Popup
    setTimeout(() => {
      successdialog.close();
    }, 3000);
  };

  const populateAccountsDropdown = (_accounts) => {
    const _options = [];
    for (var i = 0; i < _accounts.length; i++) {
      _options.push({
        value: _accounts[i].get("name"),
        label: _accounts[i].get("name"),
      });
    }
    if (options.length !== _options.length) {
      setOptions(_options);
    }
  };

  return (
    <div className="row">
      <div className="col-12">
        <form className="needs-validation" onSubmit={addTxn} id="newtxnform">
          <div className="row py-3">
            <div className="col-md-6">
              <label
                className="fw-bolder fs-smaller fonts-for-buttons-label"
                htmlFor="txnamount"
              >
                Amount
              </label>
            </div>
            <div className="col-md-6">
              <label
                className="fw-bolder fs-smaller fonts-for-buttons-label"
                htmlFor="rctxndate"
              >
                Date
              </label>
            </div>
            <div className="col-md-6">
              <input
                type="text"
                className="seventh-step form-control py-2"
                id="txnamount"
                placeholder="Amount"
                value={formattedValue}
                onChange={amountChangeHandler}
                required
              />
            </div>
            <div className="col-md-6">
              <DatePicker
                className="form-control w-100"
                id="date"
                value={date}
                onChange={onDateChange}
                maxDate={new Date()}
                clearIcon={null}
                format="dd-MM-yy"
              />
            </div>
          </div>
          <div className="select-user-step py-3">
            <Creatable
              ref={selectRef}
              options={options}
              value={
                values.account_name
                  ? {
                      value: values.account_name,
                      label: values.account_name,
                    }
                  : null
              }
              classNamePrefix="rs"
              isClearable
              placeholder="Select Party Name"
              isDisabled={khataview}
              onChange={accountChangeHandler}
              styles={{
                option: (baseStyles, state) => ({
                  ...baseStyles,
                  color: state.isSelected ? "white" : "black",
                  fontSize: 18,
                  backgroundColor:
                    state.isFocused || state.isSelected
                      ? state.isFocused
                        ? "#FFCCCB"
                        : "#dc3545"
                      : "white",
                }),
                menuList: (baseStyles, state) => ({
                  ...baseStyles,
                  backgroundColor: "white",
                }),
                clearIndicator: () => ({
                  color: "red",
                }),
              }}
            />
          </div>
          <div className="py-3">
            <input
              type="text"
              className="eighth-step form-control"
              id="newaccdetail"
              placeholder="Details"
              maxLength="50"
              value={values.details}
              onChange={detailsChangeHandler}
            />
            
          </div>
          <div className="my-3">
            <FilePicker onPick={fileFetched} ref={childRef} />
          </div>
          <div className="py-3 send-sms-step ">
            <div className="form-check form-switch">
              <input
                className="form-check-input primary p-2" 
                size={"lg"}
                type="checkbox"
                onChange={notifyChangeHandler}
                id="sendSMSCheckbox"
                checked={notify ? true : false}
              />

              <label
                className="form-check-label px-2"
                htmlFor="sendSMSCheckbox"
              >
                Send SMS Notification
              </label>
            </div>
          </div>
          {values.errormsg ? (
            <div className="form-group text-start animate__animated animate__pulse">
              <div
                className="form-check-label alert alert-danger text-capitalized"
                id="newacerror"
              >
                <i className="bi bi-exclamation-circle-fill"></i>{" "}
                {values.errormsg}
              </div>
            </div>
          ) : (
            <div className="py-2"></div>
          )}
          <div className="row my-3 ninth-step">
            <div className="col-md-6 d-grid mt-md-0">
              <button
                type="submit"
                className="btn btn-success block"
                data-bs-dismiss={
                  values.amount > 0 && values.amount < 10000000
                    ? "offcanvas"
                    : null
                }
                onClick={() => {
                  goToNextStep();
                  setMultipler(1);
                }}
              >
                <b className="fs-5">Cash In</b>
              </button>
            </div>
            <div className="col-md-6 d-grid mt-md-0">
              <button
                type="submit"
                className="btn btn-danger block py-3"
                data-bs-dismiss={
                  values.amount > 0 && values.amount < 10000000
                    ? "offcanvas"
                    : null
                }
                onClick={() => {
                  goToNextStep();
                  setMultipler(-1);
                }}
              >
                <b className="fs-5">Cash Out</b>
              </button>
            </div>
          </div>
          <br />
        </form>
      </div>
    </div>
  );
};

export default OneTimeTxn;
