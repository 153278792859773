import { createSlice } from "@reduxjs/toolkit";

const initialValues = {
  currentUser: {},
  name: "",
  phone: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState: initialValues,
  reducers: {
    setCurrentUser: (state, action) => {
      state.currentUser = action.payload;
    },
    setName: (state, action) => {
      state.name = action.payload;
    },
    setPhone: (state, action) => {
      state.phone = action.payload;
    },
  },
});

export default authSlice;
