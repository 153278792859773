import React, { useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Row, Col, ListGroup, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowCircleLeft,
  faArrowCircleRight,
  faPencil,
} from "@fortawesome/free-solid-svg-icons";
import accounting from "accounting";
import moment from "moment";
import DatePicker from "react-date-picker";
import { api } from "services";
import OpenBalance from "components/OpenBalance";
import CloseBalance from "components/CloseBalance";
import Txn from "./Txn";
import { ConfirmDelete, Modal } from "components";
//import { Loader } from 'components';
import { useNavigate } from "react-router-dom";
import { useOnboardingStore } from "store/onboarding-store";
import { debounce } from "utils";

import useMyStore from "store/useMyStore";
import useUniversalStore from "store/universalStore";
import ExpandableButton from "components/ExpandableButton";

function Home() {
  const { t } = useTranslation();
  const [txns, setTxns] = useState([]);
  const [openBalance, setOpenBalance] = useState(0);
  const [closeBalance, setCloseBalance] = useState(0);
  const [date, setDate] = useState(new Date());
  const [transactions, setTransactions] = useState([]);
  const goToNextStep = useOnboardingStore((state) => state.goToNextStep);
  const transactionRefresh = useUniversalStore(
    (state) => state.refresh.transactionRefresh
  );
  const { write, updateTransaction } = useUniversalStore();

  useEffect(() => {
    api.getAllTransactions().then((res) => {
      setTransactions(res);
    });
  }, [transactionRefresh]);

  useEffect(() => {
    const firstDate = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate() - 1,
      23,
      59,
      59,
      999
    ); //.toISOString();
    const nextDate = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      23,
      59,
      60,
      0
    ); //'.toISOString();
    if (transactions.length < 0) return;

    let trans1 = [];
    let opensum = 0;
    let closesum = 0;

    transactions?.map((transaction) => {
      const Date = transaction.get("date");
      if (Date > firstDate && Date < nextDate) {
        trans1.push(transaction);
      }
      if (Date < firstDate && transaction.get("cancelled") === false) {
        opensum += transaction.get("amount");
      }
      
    });

    setTxns(trans1);
    setOpenBalance(opensum);
    setCloseBalance(closesum);
  }, [date, transactions]);

  const nextDate = () => {
    const today = new Date();
    const newDate = moment(date).add(1, "days").toDate();
    if (newDate <= today) {
      setDate(newDate);
    }
    // console.log(today);
  };

  const prevDate = () => {
    const newDate = moment(date).subtract(1, "days").toDate();
    setDate(newDate);
  };

  const confirmit = (txn) => {
    write((prev) => {
      let tempVar = { ...prev.deleteTransactions };
      tempVar.type = "Transaction";
      tempVar.object = txn;
      return { ...prev, deleteTransactions: tempVar };
    });
  };

  const edittxn = (data) => {
    updateTransaction(data);
  };

  const TxnsView = () => {
    return (
      <div className="row">
        <span className="col-md-12">
          <div className="row background overflow-y-scroll cashbook-scroll">
            <Col md={6} xs={6} className="cashin">
              <ListGroup variant="flush" className="mb-5">
                <ListGroup.Item className="header bg-transparent">
                  <Row className="align-baseline header">
                    <Col md={12} align="center" className="text-success">
                      <i className="bi bi-arrow-down-circle-fill fs-3"></i>
                      <span className="fs-3 text-uppercase px-3">
                        <b>Cash In</b>
                      </span>
                    </Col>
                  </Row>
                </ListGroup.Item>
                <OpenBalance sum={openBalance} />
                {txns
                  .filter((txn) => txn.get("amount") > 0)
                  .map((txn, i) => {
                    if (!txn.cancelled) {
                      return (
                        <>
                          <Txn
                            data={txn}
                            key={i}
                            onEdit={edittxn}
                            onDel={confirmit}
                          />
                          <span>
                            {/* {console.log(txn.get("accountId").id)} */}
                            {/* {txn.get("accountId").name} */}
                            {/* {getAccountName(txn.get("accountId").id)} */}
                          </span>
                        </>
                      );
                    }
                    return null;
                  })}
              </ListGroup>
            </Col>
            <Col md={6} xs={6} className="cashout">
              <ListGroup variant="flush" className="mb-5">
                <ListGroup.Item className="bg-transparent">
                  <Row className="align-baseline">
                    <Col md={12} align="center" className="text-danger">
                      <i className="bi bi-arrow-up-circle-fill fs-3"></i>
                      <span className="fs-3 text-uppercase px-3">
                        <b>Cash Out</b>
                      </span>
                    </Col>
                  </Row>
                </ListGroup.Item>
                {txns
                  .filter((txn) => txn.get("amount") < 0)
                  .map((txn, i) => {
                    if (!txn.cancelled) {
                      return (
                        <Txn
                          data={txn}
                          key={i}
                          onEdit={edittxn}
                          onDel={confirmit}
                        />
                      );
                    }
                    return null;
                  })}
                <CloseBalance sum={closeBalance} />
              </ListGroup>
            </Col>
          </div>
        </span>
      </div>
    );
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row bg-app py-1">
          <div className="col-1" align="right">
            <a onClick={prevDate} className="btn btn-outline-light ">
              <FontAwesomeIcon
                icon={faArrowCircleLeft}
                size="2x"
                title="Previous Date"
              />
            </a>
          </div>
          <div className="col-2 offset-md-4 py-2 " align="center">
            <DatePicker
              value={date}
              onChange={(value) => setDate(value)}
              monthAriaLabel="Month"
              clearIcon={null}
              format="dd-MM-yyyy"
              maxDate={new Date()}
              maxDetail="month"
              className="datePicker"
              calendarIcon=""
              showMonthYearPicker={true}
            />
          </div>
          <div className="col-1 offset-4" align="left">
            <a
              onClick={nextDate}
              className="btn btn-outline-light "
              disabled={!moment(date).isBefore(new Date(), "day")}
            >
              <FontAwesomeIcon
                icon={faArrowCircleRight}
                size="2x"
                title="Next Date"
              />
            </a>
          </div>
        </div>

        {TxnsView()}

        <div className="row position-fixed bottom-0 end-0 py-4 px-4">
          <ExpandableButton
            title="New Transaction"
            clickForm="addTxnDrawer"
            onClick={(e) => goToNextStep()}
            icon={faPencil} />
        </div>
        
      </div>

      <ConfirmDelete />
    </>
  );
}

//addTxnDrawer

export default Home;
