import React from "react";
import { api } from "services";
import useUniversalStore from "store/universalStore";

function Modal(props) {
  const deleteObject = useUniversalStore(
    (state) => state.deleteTransactions.object
  );
  const deleteObjectType = useUniversalStore(
    (state) => state.deleteTransactions.type
  );
  const { updateRefresh } = useUniversalStore();
  const deleteit = () => {
    switch (deleteObjectType) {
      case "Transaction":
        api
          .deleteTransaction(deleteObject.id)
          .then(() => {
            updateRefresh("transaction");
          })
          .catch((err) => err);
        break;
      case "RecursiveTxn":
        api
          .DeleteRecursiveTxn(deleteObject)
          .then(() => {
            updateRefresh("recursive");
          })
          .catch((err) => err);
        break;
      case "AccountClose":
        if (deleteObject.get("balance") == 0) {
          api
            .closeAccount(deleteObject.id)
            .then((result) => {
              updateRefresh("account");
            })
            .catch((err) => err);
        } else {
          alert("Can't delete active account ");
        }
        break;
      case "AccountDelete":
        api
          .deleteAccount(deleteObject)
          .then((result) => {
            updateRefresh("account");
          })
          .catch((err) => err);

        break;
      default:
        console.error("No transaction to delete!");
        break;
    }
  };
  return (
    <div
      className="modal fade"
      id="delModal"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header border-0">
            <h1 className="modal-title fs-5" id="exampleModalLabel">
              Are you sure?
            </h1>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-footer border-0">
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              {" "}
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-danger"
              data-bs-dismiss="modal"
              onClick={deleteit}
            >
              <i className="bi bi-trash-fill"></i> Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Modal;
