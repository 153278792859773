
const SuccessAlert = ({message}) => {
    return (
        <div className="form-group text-start animate__animated animate__pulse">
            <div className="form-check-label alert alert-danger text-capitalized border-danger border"
                role="alert">
                <i className="bi bi-exclamation-circle-fill"></i>{' '}
                {message}
            </div>
        </div>
    )
}

export default SuccessAlert;