import { createSlice } from "@reduxjs/toolkit";

const initialValues = {
  khathaDataLoader: false,
  cashbookLoader: false,
  reminderIsActive: false,
  reminderColor: "danger",
};

const cssSlice = createSlice({
  name: "cssandloaders",
  initialState: initialValues,
  reducers: {
    setKhathaLoader: (state, action) => {
      state.khathaDataLoader = action.payload;
    },
    setcashbookLoader: (state, action) => {
      state.cashbookLoader = action.payload;
    },
    setreminderIsActive: (state, action) => {
      state.reminderIsActive = action.payload;
    },
    setReminderColor: (state, action) => {
      state.reminderColor = action.payload;
    },
  },
});

export default cssSlice;
