import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "services";
import useUserStore from "store/useUserStore";
import SuccessAlert from "alerts/SuccessAlert";

function Profile() {
  const execute = useUserStore((state) => state.execute);
  const updateUser = useUserStore((state) => state.updateUser);
  const user = useUserStore((state) => state.user);
  let navigate = useNavigate();
  //const [user, setUser] = useState(null);
  const [editing, setEditing] = useState(false);
  const [values, setValues] = useState({
    phone: user?.get("phone") || "",
    businessName: user?.get("businessName") || "",
    typeOfBusiness: user?.get("typeOfBusiness") || "",
    email: user?.get("email") || "",
    loading: false,
    error: ''
  });

  useEffect(() => {
    async function fetchData() {
      await execute();
      if (user) {
        setValues({
          phone: user.get("phone") || "",
          businessName: user.get("businessName") || "",
          typeOfBusiness: user.get("typeOfBusiness") || "",
          email: user.get("email") || "",
          error: ''
        });
      }
    }

    fetchData();
  }, []);

  const saveProfile = (e) => {
    e.preventDefault();

    updateUser(values);

    setEditing(false);
  }

  const editProfile = () => {
    setEditing(true);
  }
  
  const success = (message) => {
    setValues({
      ...values,
      successmsg: message,
      error: ''
    })

    setTimeout(() => setValues({...values,error: ""}), 5000);
  }

  const failure = (message) => {
    setValues({
      name: user.get("name"),
      phone: user.get("phone"),
      username: user.get("username"),
      email: user.get("email"),
      businessName: user.get("businessName"),
      typeOfBusiness: user.get("typeOfBusiness"),
      error: message
    });

    setTimeout(() => setValues({...values,error: ""}), 5000);
  }

  const updateField = (field, value) => {
    setValues({...values, [field]: value });
  }

  const verifyEmail = (e) => {
    e.preventDefault();
    auth.sendVerificationEmail(user.get("email")).then((result) => {
      success(result);
    }).catch((cause) => {
      failure(cause);
    })
  }

  /*
              
              
              <tr>
                  <th scope="row" className="fw-bold fs-5 py-3">Email :</th>
                  <td colSpan="2" className="position-relative">
                      <input
                          className="form-control"
                          type="email"
                          value={values.email}
                          onChange={(e) => updateField("email", e.target.value)}
                          //disabled={!editing}
                      />
                      {user.get('emailVerified') ? (
                          <span className="position-absolute start-0 ms-5 mt-2 text-success">
                              <i className="bi bi-patch-check-fill"></i>{' '}
                              Email Verified!
                              <i
                                  className="bi bi-arrow-clockwise ps-2"
                                  onClick={refresh}
                                  role="button"
                                  title="Refresh"
                              ></i>
                          </span>
                      ) : (
                          <span className="position-absolute start-0 ms-5 mt-2 text-app d-flex w-100 justify-content-around">
                              <span className="my-auto">
                                  <i className="bi bi-shield-fill-exclamation"></i>{' '}
                                  Email not verified!
                              </span>
                              <button
                                  onClick={verifyEmail}
                                  className="btn btn-app"
                                  title="Send verification Email">
                                  Verify
                              </button>
                              <i
                                  className="bi bi-arrow-clockwise ps-2 my-auto"
                                  onClick={refresh}
                                  role="button"
                                  title="Refresh"
                              ></i>
                          </span>
                      )}
                  </td>
              </tr>

  */
  return (
    <div className="container">
      <div className="row mt-5">
        <div className="col-1">
          <a role="button" className="btn btn-app" onClick={() => navigate(-1)} title="Back">
            <i className="bi bi-arrow-left"></i>
          </a>
        </div>
        <div className="col-11">
          <h2 className="">Profile Details</h2>
        </div>
      </div>
      <hr />
      <div className="row">
        <table className="my-4 table">
          <tbody className="mb-4">
            <tr>
              <th scope="row" className="fw-bold fs-5 py-3">Phone :</th>
              <td>
                  <input
                      className="form-control"
                      type="tel"
                      value={values.phone}
                      disabled
                  />
              </td>
            </tr>
            <tr>
              <th scope="row" className="fw-bold fs-5 py-3">Business Name :</th>
              <td>
                  <input
                      className="form-control"
                      type="text"
                      onChange={(e) => updateField("businessName", e.target.value)}
                      value={values.businessName}
                      disabled={!editing}
                  />
              </td>
            </tr>
            <tr>
              <th scope="row" className="fw-bold fs-5 py-3">Type of Business :</th>
              <td>
                  <select id="typeOfBusiness"
                      className="form-select"
                      defaultValue={values.typeOfBusiness}
                      disabled={!editing}
                      onChange={(e) => updateField("typeOfBusiness", e.target.value)}>
                      <option value="service">Services</option>
                      <option value="manufacturing">Manufacturing</option>
                      <option value="trading">Trading</option>
                  </select>
              </td>
            </tr>
            <tr>
              <th scope="row" className="fw-bold fs-5 py-3">Email :</th>
              <td colSpan="2" className="position-relative">
                <input className="form-control"
                  type="email"
                  value={values.email}
                  onChange={(e) => updateField("email", e.target.value)}
                  disabled={!editing} />
                  
              </td>
            </tr>
          </tbody>
        </table>
        {values.error ? <SuccessAlert message={values.error} /> : null}
        <button className="btn btn-app py-3 my-5"
            onClick={editing ? saveProfile : editProfile}>
            <b className="fs-5">{editing ? 'Save Profile' : 'Edit Profile'}</b>
        </button>
      </div>
      
    </div>
  )
}
export default Profile;
