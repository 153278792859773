import { createSlice } from "@reduxjs/toolkit";

const initialValues = {
  allUpdates: null,
};

const updateSlice = createSlice({
  name: "transction",
  initialState: initialValues,
  reducers: {
    setForUpdate: (state, action) => {
      state.allUpdates = action.payload;
    },
  },
});

export default updateSlice;
